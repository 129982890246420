import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Classes_And_Constructor_Functions() {
  const title = 'Classes And Constructor Functions'

  const paragraphs = {

    first: [insertNewLines(`Let’s look at a basic function, which would create an object when called:$4$@@$4$

    >>$4$@@$4$
    var objectCreatorFunction = function(propertyX, propertyY) {$4$@@$4$
    $4$%%$4$return {$4$@@$4$
    $4$@@$4$
      $4$%%$4$$4$%%$4$fixedPropertyName: propertyX,$4$@@$4$
      $4$%%$4$$4$%%$4$  fixedPropertyName2: propertyY$4$@@$4$
      $4$%%$4$         };$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    When called, this function will return an object, with 2 properties – as you see, the property names are fixed, depending on what they are in the function, however, the values of the property names, are given as an argument, hence we can call the function to create an object as such:$4$@@$4$
    >>$4$@@$4$
    let myNewObject = objectCreatorFunction(“gugugaga”, “not funny!”);$4$@@$4$
    <<$4$@@$4$
    this way, we have initialized the myNewObject variable, to an object, which contains two properties, with respective values “gugugaga” and “not funny!”:$4$@@$4$
    >>[$4$@@$4$]
    myNewObject = { $4$@@$4$
      $4$%%$4$  fixedPropertyName: “gugugaga”,$4$@@$4$
     $4$%%$4$   fixedPropertyName2: “not funny!”$4$@@$4$
    }$4$@@$4$
    <<
    `)],

    second: [insertNewLines(`In JavaScript however, functions that create objects are usually not created in the way above, and it is only used to help introduce to the upcoming methods.$4$@@$4$
$4$@@$4$
    Functions that create objects in JavaScript, are called CONSTRUCTOR functions, as standard practice, these functions are always capitalized, let’s look at an example of such a function and their syntax- where we’d create an identical object as above:$4$@@$4$
    >>$4$@@$4$
    var ObjectCreatorFunction = function(propertyX, propertyY) {$4$@@$4$
    $4$%%$4$this.fixedPropertyName = propertyX;$4$@@$4$
    $4$%%$4$this.fixedPropertyName2 = propertyY;$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    as you can see, there are a couple of differences to this constructor function:$4$@@$4$
     1 - No return keyword$4$@@$4$
    $4$@@$4$
    2 - Notice that the properties, are not defined by:$4$@@$4$
     $4$%%$4$   propertyName: propertyValue,$4$@@$4$
    but instead:$4$@@$4$
     $4$%%$4$   this.propertyName = propertyValue;$4$@@$4$
    $4$@@$4$
    Firstly, let’s explain what the *this* keyword does in this case.$4$@@$4$
    While *this* is a long topic, in the current case, its use is to REFER to the OBJECT, with whom, the function is associated with (an example of association is when you set let objectZ = functionX()… - where functionX is associated with the objectZ). $4$@@$4$
    $4$@@$4$
    That is to say, is that *this* stands for the name of the GIVEN object.$4$@@$4$
    $4$@@$4$
    So if we create an object, with the constructor function, this. will refer to the name of the object, that uses the constructor function.$4$@@$4$
    $4$@@$4$
    In a sense, the constructor function creates properties, through raw property creation, by pathing to a given property and then using the argument as the property value, instead of creating a property, within an object, with the argument as its value.$4$@@$4$
    $4$@@$4$
    Lastly, a key piece in understanding why this works, is that this constructor function is not called as it is, instead, the *new* keyword is used when calling a constructor function:$4$@@$4$
    >>$4$@@$4$
    let myNewObject2 = new ObjectCreatorFunction(“gugu”, “funny!”);$4$@@$4$
    <<$4$@@$4$
    and upon calling the function, a new object will be created, where myNewObject2 =  {$4$@@$4$
    $4$%%$4$fixedPropertyName : propertyX;
    $4$%%$4$fixedPropertyName2 : property }`)],

    third: [insertNewLines(`NOW THAT WE KNOW HOW CONSTRUCTOR FUNCTIONS WORK THEN, LET’S LOOK AT HOW THIS SYNTAX IS IMPROVED IN ES6.$4$@@$4$
      $4$@@$4$
    ES6 provides us with the *class* syntax:$4$@@$4$
    >>$4$@@$4$
    class ObjectCreatorFunction {$4$@@$4$
               $4$%%$4$           constructor(propertyX, propertyY) {$4$@@$4$
                                $4$%%$4$$4$%%$4$                 this.propertyName1 = propertyX;$4$@@$4$
                                $4$%%$4$$4$%%$4$                 this.propertyName2 = propertyY;$4$@@$4$
              $4$%%$4$  }$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    this will create the ObjectCreatorFunction, just like before, and the function will be invoked using the *new* keyword just like before.$4$@@$4$
    $4$@@$4$
    Something to note is that, the class syntax is just a syntax, unlike in other languages such as python, without going into detail of its relevance there.$4$@@$4$
    `)],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Classes_And_Constructor_Functions;
  