import { useMemo, useRef, useEffect } from "react"
import { useFrame, useLoader } from "react-three-fiber"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { useLoadedStateChangeContext } from "../../context/3dModelLoadContext/3dModelLoadContext";


export function Home3dSmallScreenModel({stop}) {

    const loaded = useLoadedStateChangeContext()

    useEffect(()=>{
      loaded()
    },[])
    
    const gltf = useLoader(GLTFLoader, '../scene.gltf')
    let meshRef = useRef()
  
    useEffect(() => { 
  
          function handleMouseDown(event) {
            const mouseX = event.clientX / window.innerWidth
            const mouseY = event.clientY / window.innerHeight
  
          }
      
          window.addEventListener('mousemove', handleMouseDown)
      
          return () => {
            window.removeEventListener('mousemove', handleMouseDown)
          }
  
  
        },[gltf])

        const memoizedModel = useMemo(() => gltf.scene, [gltf.scene]);
  
    useFrame((state, delta) => {
      if(!stop){
        meshRef.current.rotation.x += delta * 0.02;
        meshRef.current.rotation.y -= delta *0.02;
      }
      
    });
  
    
    return (
      <primitive ref={meshRef}  object={memoizedModel} scale={0.25} position={[0,0,0]} />
    )
  }