
import './Home.scss';

import  { useRef, useState, useEffect } from 'react';
import RotatingObjects from './components/RotatingObjects';


import { useNavigate } from 'react-router-dom';

import { PerspectiveCamera } from '@react-three/drei';

import LoadingCurtainCollapse from '../../components/LoadingCurtainCollapse';
import LoadingCurtainExpand from '../../components/LoadingCurtainExpand';
import LoadingCurtainStatic from '../../components/LoadingCurtainStatic';

import { Home3dLargeScreenModel } from '../../3dModels2/ModelJSFiles/Home3dLargeScreenModel';

import { useLoadedContext } from '../../context/3dModelLoadContext/3dModelLoadContext';


function Home() {
  const [stop, setStop] = useState(false)
  
  const[appear,setAppear] =useState(-1)

  const handleMouseEnter = () =>{
    setStop(true)
  }
  const handleMouseLeave = () =>{
    setStop(false)
  }

 
  const loading = useLoadedContext()
  
  useEffect(()=>{

    if(loading){
      setTimeout(()=>{
        setAppear(0)
        
      },900)
      setTimeout(()=>{
        setAppear(1)
        
      },1300)
    }
    
  },[loading])

  const navigate = useNavigate()

  const handleClick = (e, destination) =>{
    
    
      setAppear(2)
      e.target.style.pointerEvents = 'none'
      setTimeout(() => {
        navigate(`/${destination}`);
      }, 1300); 
    
    
  }
  const cameraRef = useRef()
  useEffect(()=>{

    function handleMove(event) {
      const screenHeight = window.innerHeight;
      const excludeHeight = screenHeight * 0.1;
      if(!stop){
        const mouseX = event.touches ? event.touches[0].clientX / window.innerWidth : event.clientX / window.innerWidth
        const mouseY = event.touches ? event.touches[0].clientY / window.innerHeight : event.clientY / window.innerHeight

        if(mouseY > 0.12 && loading === true){
          cameraRef.current.rotation.y = mouseX * 0.05
          cameraRef.current.rotation.x = mouseY * 0.05
        }   
      }
    }

    window.addEventListener('mousemove', handleMove)
    window.addEventListener('touchmove', handleMove)
    
    return () => {
      window.removeEventListener('mousemove', handleMove)
      window.removeEventListener('touchmove', handleMove)
    }
  },[cameraRef, stop, loading])

  function createBlocks(numberOfBlocks, blockClassName, destination){

    let blocksArray = [];

    for(let i = 0; i < numberOfBlocks; i ++){
      blocksArray.push(<div key={blockClassName + i} className={blockClassName}></div>)
    }
    let blockContainer = (
      <div 
          style={{pointerEvents: `${appear < 0 ? 'none' : 'unset'}`}} 
          onMouseEnter={handleMouseEnter} 
          onMouseLeave={handleMouseLeave} 
          onClick={(e)=>{handleClick(e, destination)}} 
          className='banner'
        >
          {blocksArray}
      </div>
    )
    return blockContainer
  }
  

  return (
    <div className="Home" >  


    {loading ? <LoadingCurtainExpand appear={appear}/> : <LoadingCurtainStatic />}
    {appear > 1 && <LoadingCurtainCollapse/>}
      
    <div className='pageSelectorContainer'>

    
      {createBlocks(20, 'projects-blocks', 'my-work')}

      {createBlocks(12, 'blog-blocks', 'blog')}

      {createBlocks(14, 'about-blocks', 'about')}

      {createBlocks(18, 'contact-blocks', 'contact')}
      </div>
      <div className='intro-container'>
        <h1>
          <span className='spans'>T</span>
          <span className='spans'>E</span>
          <span className='spans'>N</span>
          <span className='spans'>E</span>
          <span className='spans'>V</span>
        </h1>
        <h2>
          <span className='spans'>W</span>
          <span className='spans'>E</span>
          <span className='spans'>B</span>
          <span className='spans'>-</span>
          <span className='spans'>D</span>
          <span className='spans'>E</span>
          <span className='spans'>V</span>
        </h2>
      </div>
      <RotatingObjects stop={stop}>
        
        <PerspectiveCamera ref={cameraRef} makeDefault  position={[0,0, 5]} />
          <Home3dLargeScreenModel stop={stop}  />
        </RotatingObjects>
    </div>
  );
}

export default Home;
