function insertNewLines(string){
    let newLinesInerted = string.split('$4$').
    map( (element, index) => {
        if(element == '@@') {
            return <br key={index} />
        }else if( element == '%%'){
            return <span key={index}>&emsp;</span>
        }else {
            return element
        }
    })

    return newLinesInerted
}

export default insertNewLines

