import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";
import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Scopes() {
  const title = 'Scopes'

  const paragraphs = {

    first: [insertNewLines(`Scope, refers to the visibility of the variables.$4$@@$4$
    A variable with a GLOBAL scope for example, can be “seen” anywhere, within the whole program$4$@@$4$
    A variable with a LOCAL scope, can only be seen within its locality, an example of a locality is a function$4$@@$4$
    $4$@@$4$
    What this means, is that when a variable has a GLOBAL scope, it can also be accessed anywhere within a program, however, if you have a LOCAL variable, that is declared within its locality, it can ONLY be “seen” in its locality. If you try to access that variable outside of its locality, since it cannot be “seen” it will be undefined outside and so…undefined will be returned$4$@@$4$
    $4$@@$4$
    for example:$4$@@$4$
    >>$4$@@$4$
    let globalVariable  = 1;$4$@@$4$
    $4$@@$4$
    function randomFunction() {$4$@@$4$
    $4$%%$4$let functionScopeVariable = 2;$4$@@$4$
    $4$@@$4$
    $4$%%$4$return globalVariable + functionScopeVariable;$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    let randomArray = [globalVariable, functionScopeVariable];$4$@@$4$
    <<$4$@@$4$
    As you can see, we first declared a variable OUTSIDE of a function, hence, globalVariable can be accessed from any place within the program, so the given function can work with the globalVariable variable and will return 3 (globalVariable = 1 + functionScopeVariable = 2 = 3)$4$@@$4$
    The 2nd variable however - functionScopeVariable, is declared within the function, therefore, it can only be “seen” in the function within it is declared and so, can also be used for the return statement$4$@@$4$
    The randomArray however, is OUTSIDE the function but still tries to use the variable which was previously declared in the function and since this variable can only be accessed within the function, randomArray will equate to [1, undefined]$4$@@$4$
    As a bonus, note that when the variables are used to be given as values within the array, they are given as they are, no “”s are needed as with strings for example. $4$@@$4$
    $4$@@$4$
    Lastly, if a variable is declared without a definition (var, let, const), they will be a GLOBAL variable REGARDLESS of where they are defined in - which can be dangerous!$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    TO SUM UP:$4$@@$4$
    $4$@@$4$
    1 - Variables will have a GLOBAL SCOPE if:$4$@@$4$
    2 - variables are declared outside of a function $4$@@$4$
    3 - variables are declared without a definition (var, let, const, etc.), they will ALWAYS have a GLOBAL SCOPE (even in a function)$4$@@$4$
    $4$@@$4$
    Variables will have a LOCAL SCOPE if:$4$@@$4$
    $4$@@$4$
    1 - variables are declared within a function (with a var/let/const..)$4$@@$4$
    2 - variables are function parameters$4$@@$4$
    $4$@@$4$
    IN THE CASE OF HAVING A LOCAL VARIABLE IN A FUNCTION, WHERE THE VARIABLE WAS PREVIOUSLY DECLARED AND GLOBAL (OUTSIDE OF THE FUNCTION), THE LOCAL VARIABLE WILL TAKE PRECEDENCE OVER THE GLOBAL VARIABLE (for inside its local environment)
    $4$@@$4$
    $4$@@$4$
    With all this knowledge, we can build a QUEUE:$4$@@$4$
    A queue is an abstract DATA structure, where items are kept in order, hence, new items can be added at the back of the queue and old items can be removed from the front of the queue$4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    function queue(arr, item) {$4$@@$4$
    $4$%%$4$arr.push(item);$4$@@$4$
    return arr.shift();$4$@@$4$
    }$4$@@$4$
    let arrayOfFiveElements = [1,2,3,4,5]$4$@@$4$
    let removedElement = queue(arrayOfFiveElements, 6);$4$@@$4$
    <<$4$@@$4$
    above, we have an array which has 5 elements, when the removedElement variable calls the queue function:$4$@@$4$
    ->firstly, the given item is pushed to the end of the given arr, in this case, the given arr = ArrayOfFiveElements = [1,2,3,4,5], with 6 added, it becomes [1,2,3,4,5,6]$4$@@$4$
    ->secondly, the first value of the given array is removed, in this case, the given array now becomes [2,3,4,5,6]$4$@@$4$
    ->LASTLY, the removed value is returned, do RECALL, that operations AFTER the return keyword are executed BEFORE the return statement, but also, the .shift() returns the very element which it removes, hence, in one line of code, we instruct the function to both remove and return the value which is removed, so the removedElement variable will have the value of 1.$4$@@$4$
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Scopes;
  