import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Currying_And_Partial_Application() {
  const title = 'Currying And Partial Application'

  const paragraphs = {

    first: [insertNewLines(`The arity of a function, refers to the number of arguments a function requires. Currying a function, means to convert a function of arity N, into N number of functions of arity 1.$4$@@$4$
$4$@@$4$
    For example:$4$@@$4$
    >>$4$@@$4$
    function add(x, y) { return x + y;}$4$@@$4$
    <<$4$@@$4$
    turned into:$4$@@$4$
    >>$4$@@$4$
    function add(x) {return function(y) { return x + y} }$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    The function can then be called with the separate argument sections, either at the same or at different times:$4$@@$4$
    >>$4$@@$4$
     let number1 = add(3)(2);$4$@@$4$
    let number2 = add(3);$4$@@$4$
    $4$@@$4$
    console.log(number1)   $4$@@$4$     
    console.log(number2(2))$4$@@$4$
    <<$4$@@$4$
    console will log: 5, 5$4$@@$4$
    Here, number2 is partially applying the add function, so when number2(2) is called, it is fully calling the add function – and the output of 5 is received.$4$@@$4$
    $4$@@$4$
    But what is the usefulness of this? The usefulness, is this, is that you can partially apply the function, with MULTIPLE different parameters at MULTIPLE different stages. $4$@@$4$
    $4$@@$4$
    Here’s an example to show this:$4$@@$4$
    >>$4$@@$4$
    function describeDogFur(longOrShort) {$4$@@$4$
    $4$%%$4$return function(straightOrCurly) { $4$@@$4$
    $4$%%$4$$4$%%$4$return function(colour) {$4$@@$4$
    $4$%%$4$$4$%%$4$$4$%%$4$return ${'`'}My dog has a ${'${'}longOrShort}, ${'${'}straightOrCurly} fur and its colour is ${'${'}colour}!${'`'} $4$@@$4$                
    $4$%%$4$$4$%%$4$$4$%%$4$ }$4$@@$4$
    $4$%%$4$$4$%%$4$   }$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    let short = describeDogFur(“short”);$4$@@$4$
    let long = describeDogFur(“long”);$4$@@$4$
    $4$@@$4$
    let shortAndCurly = short(“curly”);$4$@@$4$
    let shortAndStraight = short(“straight”);$4$@@$4$
    $4$@@$4$
    let longAndCurly = long(“curly”);$4$@@$4$
    let longAndStraight = long(“straight”);$4$@@$4$
    $4$@@$4$
    let shortAndCurlyGolden = shortAndCurly(“golden”);$4$@@$4$
    let shortAndCurlyBlack = shortAndCurly(“black”);$4$@@$4$
    let shortAndcurlyWhite = shortAndCurly(“white”);$4$@@$4$
    $4$@@$4$
    let shortAndStraightGolden = shortAndStraight(“golden”);$4$@@$4$
    let shortAndStraightBlack = shortAndStraight(“black”);$4$@@$4$
    let shortAndStraightWhite = shortAndStraight(“white”);$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    let longAndCurlyGolden = longAndCurly(“golden”);$4$@@$4$
    let longAndCurlyBlack = longAndCurly(“black”);$4$@@$4$
    let longAndCurlyWhite = longAndCurly(“white”);$4$@@$4$
    $4$@@$4$
    let longAndStraightGolden = longAndStraight(“golden”);$4$@@$4$
    let longAndStraightBlack = longAndStraight(“black”);$4$@@$4$
    let longAndStraightWhite = longAndStraight(“white”);$4$@@$4$
     $4$@@$4$
    console.log(longAndStraightGolden);$4$@@$4$
    console.log(shortAndCurlyBlack);$4$@@$4$
    <<$4$@@$4$
    console will log: My dog has a long, straight fur and its colour is golden!,$4$@@$4$
                            My dog has a short, curly fur and its colour is black!$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    As lengthy of an example this is, it shows you the potential use of partial applying of curried options, as now you can use the one specific variable, which gives the one specific outcome.$4$@@$4$
    $4$@@$4$
    Partial application can get infinitely useful, if the last argument has many and possibly unpredictable inputs that you can give.$4$@@$4$
    $4$@@$4$
    In the example above, you can keep the 4 partial applications with 2 arguments, and then call the third argument at any point, with any argument (so not just the 3 colours, but any colours you wish). This will allow you to still have the “half-fixed” usage of one of the 4 partially applied functions, but with a completely non-fixed, infinite possibility 3rd argument.$4$@@$4$
    $4$@@$4$
    Lastly, you could have even straight up initialize the 4 double partially applied functions, instead of having the single partial applications before them, as:$4$@@$4$
    let longAndCurly = describeDogFur(“long”)(“curly”) – and then using longAndCurly(…)…
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Currying_And_Partial_Application;
  