import './App.css';


import { useEffect } from 'react';

import AppRoutes from './AppRoutes';


import Footer from './components/Footer';




function App() {

    useEffect(()=>{
     
    },[])

  return (
    <div className="App" >
      <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;





//debuggingArr[index].toLowerCase().replace('/', '-').split(' '). join('-')