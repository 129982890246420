import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";
import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Strings_VS_Arrays() {
  const title = 'Strings VS Arrays'

  const paragraphs = {

    first: [insertNewLines(`strings can be seen as arrays, where each element is an individual character, this means that their characters can also be accessed by bracket notation as in the case:$4$@@$4$
    >>$4$@@$4$
    let string1 = “haha funny”;$4$@@$4$
    let string2 = string1[3] + string1[6] + string1[3];$4$@@$4$
    <<$4$@@$4$
    here, string2 will hold the value of “huh”$4$@@$4$
    This is important, because it means that some code can be written for strings in the same way as with arrays, in order to provide the same functionality$4$@@$4$
    $4$@@$4$
    This however, is true only for SOME code, since though strings own some similar characteristics of arrays, not all of their characteristics are the same.$4$@@$4$
    $4$@@$4$
    This is shown in their lack of mutability:$4$@@$4$
    >>$4$@@$4$
    let string1 = “haha funny”;$4$@@$4$
    string1[5] = “b”;$4$@@$4$
    <<$4$@@$4$
    will not change the 6th element to b, making it “haha bunny”, as strings are not mutable, so with strings, the value itself needs to be reassigned if you want to change the variable:$4$@@$4$
    >>$4$@@$4$
    string1 = “haha bunny”;$4$@@$4$
    <<
    now the variable string1 (taking in mind the former code) will equal “haha bunny”$4$@@$4$
    $4$@@$4$
    that said, some array functionality can also be used for strings, such .length, which will return the number of characters within a string`)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Strings_VS_Arrays;
  