import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

import prototypesImage from '../../../../../assets/sasa1CropAndInvert.png'


function The_Prototype_Chain() {
  const title = 'The Prototype Chain'

  const paragraphs = {

    first: [insertNewLines(`Almost all objects (there are a few exceptions) in JavaScript, have a prototype.$4$@@$4$
    $4$@@$4$
    As we know, the prototype itself, is an object – { } (empty by default – if nothing is added), therefore, since most objects have a prototype…the prototypes themselves, also have a prototype!$4$@@$4$
    $4$@@$4$
    In JavaScript, EVERY object, has been constructed by a constructor function, even those that did not (seem) to call on one. $4$@@$4$
    This constructor function, is the base constructor function, within the language: Object( )$4$@@$4$
    $4$@@$4$
    Therefore, every time you create an object, the *new Object( )* function is called:$4$@@$4$
    $4$@@$4$
    let obj1 = { };   →   Calls *new Object( );* function, to create an object,
    just like when we create an object by calling our Baby constructor:$4$@@$4$
    $4$@@$4$
    let babyx = new Baby( );$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    Since we know that every object (and function!) has a prototype, the base Object( ) constructor ALSO has a prototype – Object.prototype - and the prototype of this prototype is the BASE prototype - null`
    )],

    second: [insertNewLines(`As you can see, the hypothetical obj1 object, is created, using the Object( ) constructor.$4$@@$4$
    In doing so, obj1 inherits the prototype of Object( ), which is Object.prototype.$4$@@$4$
    Lastly, the Object.prototype itself is null.$4$@@$4$
    Previously, we learned that NON-PRIMITIVE values, are passed in by reference, which means that they are given an address, instead of an actual value, and the address itself is the one which has the value.$4$@@$4$
    $4$@@$4$
    So far, we covered, that the constructor functions have a .prototype property.$4$@@$4$
    $4$@@$4$
    What we did not discuss, however, is the .__proto__ (double underscores on each side) property, which all objects have – this is the prototype property, for the objects.$4$@@$4$
    $4$@@$4$
    What this property does, is that it refers to the same address, to which the .prototype property of functions does.$4$@@$4$
    $4$@@$4$
    THEREFORE:$4$@@$4$
    >$4$@@$4$
    objectName.__proto__ == object’sConstructorFunc.prototype$4$@@$4$
    objectName.__proto__ === object’sConstructorFunc.prototype$4$@@$4$
    <$4$@@$4$
    WILL BOTH BE TRUE$4$@@$4$
    $4$@@$4$
    This can also be represented by:$4$@@$4$
    >$4$@@$4$
    objectName.__proto__ == objectName.constructor.prototype$4$@@$4$
    objectName.__proto__ === objectName.constructor.prototype$4$@@$4$
    <$4$@@$4$
    Where you are accessing the prototype of the constructor property on the object$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    REMEMBER – THE PATHWAYS ON BOTH SIDES RETURN OBJECTS$4$@@$4$
    OBJECTS HAVE VALUE OF ADDRESSES, NOT ACTUAL VALUES$4$@@$4$
    THEREFORE, THIS EQUALITY SHOWS THAT THEY REFER TO THE SAME ADDRESS.$4$@@$4$
    $4$@@$4$
    Hence, if you change any one of them, you are changing the value of the address, not the address itself and therefore all other references will also equal that same changed value.$4$@@$4$
    $4$@@$4$
    If you now remember, when you add multiple prototype properties AT ONCE, by REASSIGNING the prototype property, it therefore means, that you are GIVING THE WHOLE PROTOTYPE VARIABLE, A NEW ADDRESS, and this is why the older instances, will retain the OLD prototype, as they are referencing the OLD address, whereas the newer instances, post reassignment, will get the NEW prototype, as they are referencing the NEW address.$4$@@$4$
    $4$@@$4$
    Before continuing, note that while objects have a .__proto__ property, functions have .prototype property, if you try to access a path of:$4$@@$4$
    >$4$@@$4$
    objectName.prototype$4$@@$4$
    <$4$@@$4$
    the path will give undefined, as such a “thing” does not exist. $4$@@$4$
    $4$@@$4$
    HOWEVER, since as the noted above, of that - prototype properties themselves have a prototype, we can access the .__proto__ of a .prototype of a function.$4$@@$4$
    $4$@@$4$
    Recall our Baby( ) constructor function:$4$@@$4$
    >>$4$@@$4$
    function Baby(name, age)  {  $4$@@$4$
    $4$@@$4$
    $4$%%$4$this.name = name;$4$@@$4$
    $4$%%$4$this.age = age;$4$@@$4$
    $4$%%$4$this[‘makes sounds’] = ‘gugu gaga’;$4$@@$4$
    $4$%%$4$this.saySounds = function( ) { return ${'`${'}this.name} makes {$this[“makes sounds”]} sounds.${'`'} };$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    It has a prototype, this prototype is shared amongst all of its instances, where:$4$@@$4$
    BabyInstance.__proto__ ==/=== Baby.prototype$4$@@$4$
    $4$@@$4$
    As the paths on both sides, refer to the same address.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    Since the prototype of Baby( ) itself, also has a prototype, we can check it by:$4$@@$4$
    Baby.prototype.__proto__$4$@@$4$
    $4$@@$4$
    Where this, will refer, to the pathway of the prototype of the prototype of Baby( ).$4$@@$4$
    $4$@@$4$
    We know that the instances of Baby( ) inherit their prototype form Baby( ), but where does the prototype of Baby( ) inherit its prototype from?$4$@@$4$
    $4$@@$4$
    The prototype of Baby( ), inherits its prototype form the main Object( ) constructor function, therefore:$4$@@$4$
    Baby.prototype.__proto__ ==/=== Object.prototype$4$@@$4$
    $4$@@$4$
    This can also be shown as:$4$@@$4$
    BabyInstance.__proto__.__proto__ ==/== Object prototype$4$@@$4$
    $4$@@$4$
    As BabyInstance.__proto__ ==/== Baby.prototype, so therefore, BabyInstance.__proto__.__proto__ means the proto of BabyInstance’s proto, which == the proto of Baby.prototype.$4$@@$4$
    $4$@@$4$
    As a side note:$4$@@$4$
    In terms of terminology, the objects from which the property is inherited (the parent) is known as the supertype, whereas those who inherited the properties (the children) are the subtypes.$4$@@$4$
    
    If you follow the diagram on the next page therefore, BabyInstance is the subtype of Baby.prototype and Baby.prototype is the supertype of BabyInstance, but Object.prototype would be the supertype for both Baby.prototype and BabyInstance, whereas both of them would be the subtypes of Object.prototype. `)],

    third: [insertNewLines(`From the diagram, you can see that a chain forms, all the way to BabyInstance, which would be the object created by the Baby( ) constructor.$4$@@$4$
$4$@@$4$
    The importance of this chain, is that if you attempt to use any properties within BabyInstance, upon failure to find the searched property, JavaScript will them search for a property in Baby.prototype, when that results to a failure too, JavaScript will finally go on to the last part of the chain – the prototype of Baby.prototype – Baby.prototype.__proto__ or Object.prototype, and if a failure is given again, this time JavaScript will finally stop the search and declare the searched property as undefined – as it does not exist.$4$@@$4$
    $4$@@$4$
    ON THE OTHER HAND, any properties that do exist at one or more levels within the chain, JavaScript will return the value of the firstly encountered match and the search will stop.$4$@@$4$
    $4$@@$4$
    This is important, because if we have two properties with the same name, the firstly encountered one will be the one that is returned, regardless of the values of the properties.$4$@@$4$
    $4$@@$4$
    for example, if we create a new Baby instance:$4$@@$4$
    >>$4$@@$4$
    let BabyInstance1 = new Baby(“Boris”, 3);$4$@@$4$
    <<$4$@@$4$
    the BabyInstance1 will have its own properties of name, age, makes sounds and the function saySounds, even though the BabyInstance1 has the ‘makes sounds’property of ‘gugu gaga’, as given by default, we can add a new ‘makes sounds’ property to the prototype of Baby, where:$4$@@$4$
    >>$4$@@$4$
    Baby.prototype[‘makes sounds’] = “bubu gaga”;$4$@@$4$
    <<$4$@@$4$

    This will also work if we instead do:$4$@@$4$
    >>$4$@@$4$
    BabyInstance1.__proto__[‘makes sounds’] = “bubu gaga”;$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    Now, there are two ‘makes sounds’ properties, one in the address of Baby.prototype(which == “bubu gaga”) and one, which is an own property of BabyInstance1(which == “gugu gaga”)$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    If we try to access BabyInstance1[‘makes sounds’], we will get the string ‘gugu gaga”, whereas BabyInstance.__proto__[‘makes sounds’],   will give us the string “bubu gaga”.$4$@@$4$
    $4$@@$4$
    If we now delete the own property of BabyInstance1:$4$@@$4$
    >>$4$@@$4$
    delete BabyInstance1[‘makes sounds’];$4$@@$4$
    console.log(BabyInstance1[‘makes sounds’]);$4$@@$4$
    <<$4$@@$4$
    console will display “bubu gaga”$4$@@$4$
    $4$@@$4$
    When we try to access BabyInstance1[‘makes sounds’], we will get “bubu gaga”, as JavaScript looks through BabyInstance1 – no match – therefore searches through its prototype – match is found! – return matched value$4$@@$4$
    $4$@@$4$
    Now that you know all that, you can understand that there is a further meaning to all this, as a lot of the functions that can be used, such as .hasOwnProperty(), .sort(), .indexOf(), etc., are actually methods within the Object.prototype, and this is why we can use them freely the way we do, on objects which do not have these properties, so really, every time we use them, JavaScript goes to the Object.prototype to find these methods and execute them on the object we use them on!
    `)],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          <div style={{background: `url(${prototypesImage})`, width: '100%', height: '80vh', backgroundRepeat:'no-repeat', backgroundSize:'contain', backgroundPosition: 'center'}}></div>
          

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          <h5 style={{textAlign:'center'}}>{'(image bellow scrollable on phone)'}</h5>
          <div className="prototype-chain-second-image-container">
            <div className='prototype-chain-second-image' ></div>
          </div>
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default The_Prototype_Chain;
  