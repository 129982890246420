import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function The_Prototype_Chain_Further_Examples() {
  const title = 'The Prototype Chain: Further Examples'

  const paragraphs = {

    first: [insertNewLines(`)
    Don’t repeat yourself is a principle of programming, where it is the idea – to literally not repeat your code where possible. This makes both editing and debugging easier, as you will have to go back and edit/debug less code, when you do.$4$@@$4$
    An example where code is repeated:$4$@@$4$
    Say we declare 2 constructor functions, where both of these would create objects, in which, one of the properties which you would like to add is the same, but apart from this property, they are different objects, with different characteristics.$4$@@$4$
    $4$@@$4$
    For example:$4$@@$4$
    >>$4$@@$4$
    function Bird( ) {$4$@@$4$
    $4$%%$4$this.legs = 2;$4$@@$4$
    $4$%%$4$this.eyes = 2;$4$@@$4$
    $4$%%$4$this.mouthType = “beak”;$4$@@$4$
    $4$%%$4$this.flight = function( ) { console.log(“I’M FLYING B17CH35”)};$4$@@$4$
    };$4$@@$4$
    $4$@@$4$
    function Plane( ) {$4$@@$4$
    $4$%%$4$this.material = “Aluminium”;$4$@@$4$
    $4$%%$4$this.rangeInKm = 500;$4$@@$4$
    $4$%%$4$this.flight = function( ) { console.log(“I’M FLYING B17CH35”)};$4$@@$4$
    };$4$@@$4$
    <<$4$@@$4$
    Both functions would create different objects, but amongst their properties, there is a method, which is identical.$4$@@$4$
    $4$@@$4$
    By using the prototype chain, you can avoid repeating your code, by creating a 3rd function, which creates the flight method in its prototype and then add the flight method to the prototype of either of these constructor functions.$4$@@$4$
    $4$@@$4$
    Therefore, the better version, without repetition (continuing off of the code above, but with a scenario where the this.flight = … is not included in either constructors):$4$@@$4$
    >>$4$@@$4$
    function Actions( ) { };         /* creates actions constructor */$4$@@$4$
    $4$@@$4$
    Actions.prototype = {        /* assigning a prototype with all the props we want */$4$@@$4$
$4$%%$4$constructor : Actions,       /* add the constructor to not lose the identity */$4$@@$4$
    
$4$%%$4$flight : function( ) { console.log(“I’M FLYING B17CH35”);} $4$@@$4$
    $4$%%$4$};$4$@@$4$
    <<$4$@@$4$
    We now have our Actions constructor and have created its prototype, where the flight method exists.$4$@@$4$
    $4$@@$4$
    Therefore, we can now just add the flight method from this constructor prototype, onto the prototypes of the other constructors$4$@@$4$
    >>$4$@@$4$
    Plane.prototype = new Actions;$4$@@$4$
    Bird.prototype = new Actions;$4$@@$4$
    <<$4$@@$4$
    And an even better way to do this, would be by using:$4$@@$4$
    >>$4$@@$4$
    Plane.prototype = Object.create(Actions.prototype);$4$@@$4$
    Bird.prototype = Object.create(Actions.prototype);$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    But what did we do exactly?$4$@@$4$
    $4$@@$4$
    Looking at the first example, where x.prototype = new Actions, what we did, is that we reassigned the prototypes to a new Actions object. $4$@@$4$
    $4$@@$4$
    It is important to understand, that we did not directly set the Plane/Bird.prototype to Actions.prototype!$4$@@$4$
    $4$@@$4$
    Instead, we made the Plane/Bird prototype object to be an instance of the Actions constructor. The Actions constructor creates a { } object, as shown. However, while the Plane/Bird.prototype properties remain empty objects, they inherit the prototype of the Actions constructor, hence, when using Plane/Bird.prototype.flight( ), even though flight is not directly, and own property of the prototype, since it has inherited the property from actions, when JS doesn’t find it on the Plane/Bird.prototype, it will then go on to search the Actions.prototype – where it will be found.$4$@@$4$
    $4$@@$4$
    This is very useful, because we retain the empty Plane/Bird.prototype object, to which we can add more properties, while still having the inheritance of the flight( ) method.$4$@@$4$
    $4$@@$4$
    Lastly, the Object.create(InputObject) method, creates a new object (name/path of new object is on the left hand side) and sets the input/argument object, as the new object’s prototype$4$@@$4$
    $4$@@$4$
    The reason as to why Object.create(obj) is a better alternative, is a bit too complex and for now, it can only be said that the x = new y creates certain inheritance disadvantages, which do not come when using Object.create(obj).$4$@@$4$
    $4$@@$4$
    So now…when we create instances of Bird/Plane( ), they will have access to the flight method!:$4$@@$4$
    >>$4$@@$4$
    let boeing747 = new Plane( );$4$@@$4$
    $4$@@$4$
    let sparrow = new Bird( ):$4$@@$4$
    $4$@@$4$
    boeing747.flight( );$4$@@$4$
    sparrow.flight( );$4$@@$4$
    <<$4$@@$4$
    console will display: I’M FLYING B17CH35, I’M FLYING B17CH35$4$@@$4$
    $4$@@$4$
    and just to show everything else mentioned beforehand:$4$@@$4$
    >>$4$@@$4$
    console.log(sparrow);$4$@@$4$
    console.log(sparrow.__proto__);$4$@@$4$
    console.log(sparrow.__proto__.__proto__);$4$@@$4$
    <<$4$@@$4$
    console will display:$4$@@$4$
    $4$@@$4$
    { $4$@@$4$
    $4$%%$4$this.legs = 2;$4$@@$4$
    $4$%%$4$this.eyes = 2;$4$@@$4$
    $4$%%$4$this.mouthType = “beak”;$4$@@$4$
    }        /*(remember that we removed the method from the Bird( ) constructor) */$4$@@$4$
    $4$@@$4$
    { }$4$@@$4$
    $4$@@$4$
    {flight:  [Function]}$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    Our sparrow instance object has remained as we need it from our constructor.$4$@@$4$
    $4$@@$4$
    sparrow.__proto__ == Bird.prototype – this prototype has remained unchanged (but still reassigned!)$4$@@$4$
    $4$@@$4$
    Lastly – sparrow.__proto__.__proto__, which==Bird.prototype.__proto__, has our flight method, and therefore our instances will be able to access it!$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    HOWEVER, since we changed the Bird/Plane prototypes to the new Action objects, it means that we also reset their constructor properties, which now==Actions, so make sure to reverse this!:$4$@@$4$
    >>$4$@@$4$
    Plane.prototype.constructor  = Plane;$4$@@$4$
    Bird.prototype.constructor = Bird;$4$@@$4$
    console.log(sparrow.constructor);$4$@@$4$
    <<$4$@@$4$
    console will display Bird$4$@@$4$
    $4$@@$4$
    **MAJOR NOTE**$4$@@$4$
    Keep in mind, after all this, if you need, you can “alter” (you will not exactly be altering as shown in a second), the given function which you want to be shared in a common prototype.$4$@@$4$
    $4$@@$4$
    Remember, that the common function lies in Actions.prototype and Actions.prototype == Plane/Bird.prototype.__proto__$4$@@$4$
    …so it lies in the prototype of the Plane/Bird prototype, not just the Plane/Bird prototype itself.$4$@@$4$
    $4$@@$4$
    Therefore, if you for example, want to have a different flight method for Plane, while retaining the other flight method:$4$@@$4$
    >>$4$@@$4$
    Plane.prototype.flight = function( ) { console.log(“flying GIGA fast”);$4$@@$4$
    <<$4$@@$4$
    Now, we have added a flight method, as a property of the Plane.prototype$4$@@$4$
    $4$@@$4$
    This means, that when we call flight from an instance, as the flight method is searched throughout the instance and its prototypes, it will first be encountered in Plane.prototype (or instanceName.__proto__), BEFORE Plane.prototype.__proto__ (or instanceName.__proto__.__proto__)!$4$@@$4$
    $4$@@$4$
    And yes, the former flight function still remains in the Plane.prototype.__proto__, but since the search stops right after the first encounter of flight( ), we get to use our new flight method.$4$@@$4$
    Keep in mind that at the end of all, Actions.prototype, Plane.prototype.__proto__ and Bird.prototype.__proto__, as all just referencing the SAME ADDRESS, with an object within it.
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default The_Prototype_Chain_Further_Examples;
  