import React, { useRef, useEffect } from 'react';



//props.content
//props.type - 69 for letters; 666 for numbers, or a sring with custom characters for having the changed characters be customized
//props.typeSpeed
//props.characterIterations

//props.switch - creates a scenario where some content is written first and after a delay, the content is switched to another one
//NEEDS TO BE SET TO FALSE IF YOU DO NOT WANT A SWITCH
//the value needs to be an object with the properties:

//switchedContent: 'stringOfTheNewlySwitchedContent'
//delay: number of ms between the content switch - note, the delay is designed to start AFTER THE FIRST TYPING IS FULLY FINISHED

//switchStartIndex - index at which the switch to start
//switchEndIndex - index at which the switch to end
//the idea of the last two properties is to be able to type out something and then only switch a particular bit, for example: 
//You are very good => You are very bad, where only the word good will be switched to bad, while the rest will remain static
//if you want to change the whole content, just give an index of 0 and initialContent.length



function DelayedText1(props) {

    
    const h3Ref = useRef()
    const letters = 'QWERTYUIOPASDFGHJKLZXCVBNM'
    const numbers = '1234567890'
    let contents = props.content

   

    
    useEffect(()=>{
       
        console.log(props.switch)
       let iterations = 0
    
            const interval = setInterval(() => {
                

                h3Ref.current.textContent = props.content
                .split("")
                .map((element, index) => {

                    if(index > props.switch.switchStartIndex && index < props.switch.switchEndIndex && props.switch){
                        if(index < iterations){
                            return props.content[index]
                        }
                    
                        return props.type == 69 ? letters[Math.floor(Math.random() * 26 )] 
                        : props.type == 420 ? numbers[Math.floor(Math.random() * 10 )]
                        : props.type[Math.floor(Math.random() * props.type.length)]

                    }else if(index < iterations){
                        return props.content[index]
                    }
                
                    return props.type == 69 ? letters[Math.floor(Math.random() * 26 )] 
                           : props.type == 420 ? numbers[Math.floor(Math.random() * 10 )]
                           : props.type[Math.floor(Math.random() * props.type.length)]
               
                })
                .join("")
        
                if(iterations >= props.content.length){
                    clearInterval(interval)
                }
                iterations += 1 / props.characterIterations

           }, props.typeSpeed);
           
           
        
    

    if(props.switch) {
        setTimeout(()=>{
            iterations = props.switch.switchStartIndex 
            const interval = setInterval(() => {
                if(h3Ref.current == null){
                    clearInterval(interval)
                }
                
                h3Ref.current.textContent = props.content
                .split("")
                .map((element, index) => {
                    if(index > props.switch.switchStartIndex && index < props.switch.switchEndIndex){
                        if(index < iterations){
                            return props.switch.switchedContent[index]
                        }
                        return props.type == 69 ? letters[Math.floor(Math.random() * 26 )] 
                           : props.type == 420 ? numbers[Math.floor(Math.random() * 10 )]
                           : props.type[Math.floor(Math.random() * props.type.length)]
                           
                    }else{
                        return element
                    }
                })
                .join("")
        
                if(iterations >= props.switch.switchEndIndex){
                    clearInterval(interval)
                }
                iterations += 1 / props.characterIterations
        
                
            }, props.typeSpeed);
        }, props.switch.delay + props.content.length * props.typeSpeed * props.characterIterations)
    }

    return () => clearInterval(interval)
    
    },[])
  
    
    
    return (
      <span ref={h3Ref} className="DelayedText1" >  
        
      </span>
    );
  }
  
  export default DelayedText1;