import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";
import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";


function Variables_Data_Types_And_Values() {

  const title = 'Variables, Data Types and Values'

  const paragraphs = {

    first: ['A variable - is a (named) container, which contains a particular value, or data. More exactly, it is an abstract storage location, which can (but does not necessarily) contain data.',
    "In JavaScript, you can declare variables with one of the 3 keywords – var, let and const.",
    "var – initiates a variable, which can be re-declared (declared - creating the variable and giving it its name) without giving an error, when initiating a new var variable, with the SAME name. Var variables are function-scoped variables (to be discussed later).",
    "let – initiates a variable, which CANNOT be redeclared without giving an error, when initiating a new let variable, with the same name. Let variables are block-scoped variables (to be discussed later).",
    "const – after initiated, a const variable can neither be re-declared, nor be reinitialized without giving an error.",
    insertNewLines(`EXAMPLES:$4$@@$4$
    For var variables:$4$@@$4$
    >>$4$@@$4$
    var x = 1;$4$@@$4$
    var x = 2;$4$@@$4$
    <<$4$@@$4$
    var x will equal 2$4$@@$4$
    $4$@@$4$
    For let variables:$4$@@$4$
    >>$4$@@$4$
    let y =1;$4$@@$4$
    let y= 2;$4$@@$4$
    <<$4$@@$4$
    the console will display and error as you tried to declare a variable with the same name – keep in mind that a let variable can still be changed, simply by not including the --let--, but just using “y” (the variable name), this will change the value of the variable, it is not re-declaring it. 
    $4$@@$4$
    $4$@@$4$
    For const variables:$4$@@$4$
>>$4$@@$4$
const z = 1;$4$@@$4$
z = 2;$4$@@$4$
<<$4$@@$4$
in the above example, if the ‘z’ variable was a --let-- variable – it would  be equal to 2, but since z is a const, it means that its value cannot be changed at all, hence, the console will give an error.
$4$@@$4$
$4$@@$4$
*when assigning a value, e.g. var value = x+y; , everything on the right side of the equals sign is resolved BEFORE the value is assigned.`)
    ],

    second: [insertNewLines(`Data type - refers to the classification that specifies the type of value which is stored within a variable. $4$@@$4$
    $4$@@$4$
    JavaScript provides eight different data types which are:$4$@@$4$
    undefined, null, boolean, string, symbol, bigint, number, and object.$4$@@$4$
    $4$@@$4$
    For example:$4$@@$4$
    >>$4$@@$4$
    let x = 1;$4$@@$4$
    let y = “one”;$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    x will be storing a number, whereas y will be storing a string - which has to be encased with either single or double quotes (but not both at the same time - such as  “this’)`)],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],


  }



  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        {/* <hr className="splitter-hr"/> */}
        
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Variables_Data_Types_And_Values;
  