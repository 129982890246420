import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";
import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function String_Syntax() {

  const title = 'String Syntax'

  const paragraphs = {

    first: [insertNewLines(`As already stated, strings have to be encased in single or double quotes. This however means that the program can sometimes confuse them if you want to have quotes within the string itself, for example:$4$@@$4$
$4$@@$4$
    >>$4$@@$4$
    let string = ‘I’m good’;$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    instead of creating the string -I’m good-, the program will confuse the single quote between I and m and use it as the ending of the string - hence, only creating the string ‘I’.$4$@@$4$
    $4$@@$4$
    This can be evaded in two ways:$4$@@$4$
    $4$@@$4$
    1 - Encasing the string with different quotes, to the ones which you want to use, for example:
    
    >>$4$@@$4$
    let string = “I’m good”;$4$@@$4$
    <<$4$@@$4$
    
    Here, the program will correctly create the string - I’m good , as it does not confuse the single quote within it with the double quotes outside it. This is a quick way to avoid the quotes being confused, however, what if you want a word which contains both types of strings?$4$@@$4$
    $4$@@$4$
    2 - quotes can be fully “escaped” - i.e. giving the program a command with which to write a particular character, in this case, quotes:$4$@@$4$
    
    >> $4$@@$4$
    let string = “and then he said /“I’m good/””;$4$@@$4$
    <<$4$@@$4$
    as you can see, before every quote character which we wanted to be a part of the string, we put a --/--, this makes the program give an input of a quote character within the string, rather than using the quotes as a mark for the end of the string.$4$@@$4$
    $4$@@$4$
    The quote characters are not the only characters which can be written using an escape sequence. Other characters include:$4$@@$4$
    $4$@@$4$
    \\\\   =   backslash$4$@@$4$
    \\${'n'}  =   newline$4$@@$4$
    \\${'r'}   =   carriage return$4$@@$4$
    \\${'t'}  =   tab$4$@@$4$
    \\${'b'}  =  word boundary$4$@@$4$
    \\${'f'}   =   form feed
    `)],

    second: [insertNewLines(`Strings can be different strings can be added together, into a variable, such that the variable’s value is the combined value of both strings, for example:$4$@@$4$

    >>$4$@@$4$
    let string = “hey ” + “you”$4$@@$4$
    <<$4$@@$4$
    the example above is not especially useful, as you can simply create a string “hey you” to begin with, however, concatenation becomes very useful when you combine multiple variables together, such as:$4$@@$4$
    
    >>$4$@@$4$
    let string1 = “hey”;$4$@@$4$
    let string2 = “you”;$4$@@$4$
    $4$@@$4$
    let stringCombined = string1 + ” ” + string2 + “, come here“ ;$4$@@$4$
    <<$4$@@$4$
    in the above example, stringCombined will equal “hey you, come here”.$4$@@$4$
    It is also important to note that when concatenating, spaces need to be added additionally!`)],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],


  }




  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
       
        
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default String_Syntax;
  