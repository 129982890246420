import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Own_Constructor_And_Prototype_Properties() {
  const title = 'Own Constructor And Prototype Properties'

  const paragraphs = {

    first: [insertNewLines(`Own properties, are properties that are defined DIRECTLY, within/on the objects, be it instance or not instance objects.$4$@@$4$
    For example:$4$@@$4$
    >>$4$@@$4$
    let obj1 = {$4$@@$4$
    $4$%%$4$prop1  : “val1”$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    Here, prop1 is directly defined onto the obj1 object and can be accessed by obj1.prop1$4$@@$4$
    These properties can be checked by .hasOwnProperty, which will return true$4$@@$4$
    THIS ALSO MEANS, THAT ALL OF THESE PROPERTIES ARE SEPARATE PIECE OF DATA, YOU DON’T NEED TO REMEMBER THIS FOR NOW, BUT JUST KEEP IT IN THE SIDE MEMORY$4$@@$4$
    Prototype properties, are properties, which exist in every constructor (and objects) – they exist within the prototype property$4$@@$4$
    The prototype, is an “unseen” property, by default - it is just an empty object {}$4$@@$4$
    However,$4$@@$4$
    the properties in the PROTOTYPE property, are SHARED by ALL INSTANCES of a given constructor$4$@@$4$
    $4$@@$4$
    Let’s show what does all of the above mean by using code examples:$4$@@$4$
    >>$4$@@$4$
    let baby3 = new Baby(‘Zoro’, 3); //create baby3 object – name=Zoro, age = 3$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    Firstly, let’s remind ourselves that the PROTOTYPE property of the Baby constructor is an empty object – { }$4$@@$4$
    $4$@@$4$
    If we now add some properties within that empty object:$4$@@$4$
    >>$4$@@$4$
    Baby.prototype,newPrototypeProperty = “prototypePropertyValue1”;$4$@@$4$
    <<$4$@@$4$
    now, our “hidden” prototype property within Baby, doesn’t have a value of { }, but instead, has a value of$4$@@$4$ 
    { newPrototypeProperty : “prototypePropertyValue1”}$4$@@$4$
    So it now has 1 (prototype) property within it.$4$@@$4$
    $4$@@$4$
    This property will now be INHERITED by ALL INSTANCES of Baby, thus, you can access this property by pathing from the INSTANCES of Baby, such as baby3.$4$@@$4$
    $4$@@$4$
    Furthermore, since this is an INHERITED property, when accessing it through instances, it can/needs to be accessed by your regular pathing:$4$@@$4$
    >>$4$@@$4$
    console.log(baby3.newPrototypeProperty);$4$@@$4$
    <<$4$@@$4$
    console will display: prototypePropertyValue1$4$@@$4$
    $4$@@$4$
    As you can see – you can/need to use the prototype property name straight after the baby3 path, instead of baby3.prototype.newPrototypeProperty, as it was originally set in the Baby constructor, ***in fact, if you do that, something else will happen, which will be covered later in the OOP section.$4$@@$4$
    $4$@@$4$
    HOWEVER, now, if you remember that OWN properties are separate pieces of data, it is here, where this comes of importance.$4$@@$4$
    $4$@@$4$
    INHERITED properties ARE NOT OWN properties – therefore, if you try to use the .hasOwnProperty method on them, false will be returned$4$@@$4$
    $4$@@$4$
    KEEP IN MIND, ALL OBJECTS HAVE A PROTOTYPE, BUT WHEN CREATING INSTANCES OF CONSTRUCTOR FUNCTIONS, THE INSTANCES INHERIT THE PROTOTYPE PROPERTY TOO.$4$@@$4$
    $4$@@$4$
    You can check the “inheritance relationship” of an object by using the isPrototypeOf method:$4$@@$4$
    >>$4$@@$4$
    Baby.prototype.isPrototypeOf(baby3); // will return true$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    As a side note, remember, that if .hasOwnProperty returns true for own properties, you can use its false return, to get an object’s not own properties, using the following loop:$4$@@$4$
    >>$4$@@$4$
    for (let properties in baby3) {$4$@@$4$
    $4$%%$4$      if(baby3.hasOwnProperty(properties)) {$4$@@$4$
    $4$%%$4$$4$%%$4$            console.log(${'`${'}properties}${'`'})$4$@@$4$
    $4$%%$4$      }else{$4$@@$4$
    $4$%%$4$$4$%%$4$            console.log(${'`${'}properties}${'`'})$4$@@$4$
    $4$%%$4$      }$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    in this loop, if(baby3.hasOwnProperty(properties)) will return true for all (4) of the OWN properties of baby3, which will then have each of their names logged by the console. Then, the if(baby3.hasOwnProperty(properties)) will return false for the prototype properties of baby3, as they are not own properties of baby3 – each of these properties’ names will then also be logged by the console.$4$@@$4$
     $4$@@$4$
    Effectively, this is a loop that iterates through ALL properties of an object – BOTH owned and non-owned (such as inherited/prototype properties)$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    Constructor properties, are special properties, which get created in ALL INSTANCES of a constructor. The value of this property, is the name of the constructor itself.$4$@@$4$
    $4$@@$4$
    In the case of the baby3 instance (as well as baby1 & 2…):$4$@@$4$
    baby3.constructor === Baby – is true $4$@@$4$
    $4$@@$4$
    In a sense, this property helps to check the kind of object it is and where did it come from, just like the *instanceof* operator.$4$@@$4$
    $4$@@$4$
    HOWEVER, the constructor property CAN be overwritten, as will also be seen later on. This means that it is better to check the object using the *instanceof* operator
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Own_Constructor_And_Prototype_Properties;
  