import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function SUSSY() {
  const title = ''

  const paragraphs = {

    first: [insertNewLines(`
    Exporting is when you take code out from your file, into other files.$4$@@$4$
    $4$@@$4$
    In order to export, you need to make you code exportable, you do this by adding the following syntax:$4$@@$4$
    >>$4$@@$4$
    export let variableName = x;$4$@@$4$
    <<$4$@@$4$
    So by adding an export keyword before your variable.$4$@@$4$
    $4$@@$4$
    This can also be done in the following syntax:$4$@@$4$
    >>$4$@@$4$
    let variableName = x;$4$@@$4$
    $4$@@$4$
    export {variableName};$4$@@$4$
    <<$4$@@$4$
    and of course, you can do multiple values at the same time by adding a comma between them. $4$@@$4$
    `)],

    second: [insertNewLines(`You can also set a DEFAULT EXPORT, usually used to export a fallback value or a single – default value:$4$@@$4$
    >>$4$@@$4$
    export default function add(x, y) { return x + y;}$4$@@$4$
    <<$4$@@$4$
    We have created the function add, which takes in two numbers and returns their sum. By adding the export default keywords before our function, it will become the default export.$4$@@$4$
    $4$@@$4$
    Since the default export is ONLY ONE (since it is a default…) – it’s variable does not need to be named:$4$@@$4$
    >>$4$@@$4$
    export default function(x, y) { return x + y;}$4$@@$4$
    <<$4$@@$4$
    The name is not necessary because since the default is only one, it is known that the export will be this exact one
    `)],

    third: [insertNewLines(`Importing is the opposite of exporting, it is when you take code from files outside of your own, and take it to your own file.$4$@@$4$
$4$@@$4$
    You do this by the following syntax:$4$@@$4$
    <<$4$@@$4$
    import {variable/function1, variable/function2, variable/function3…} from ‘./filename.js’; $4$@@$4$
    <<$4$@@$4$
    The *./* before the filename tells the import to look for the filename.js file, in the same folder as the current file.$4$@@$4$
    The destination of the file’s import (including the ./ in this case) needs to be encased in ‘’s.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    Instead of importing by name, you can also import ALL the contents of a file by:$4$@@$4$
    >>$4$@@$4$
    import * as objectName from ‘./filename.js’;$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    This will import all of the file’s contents and store each imported variable as a property of an object, to which you’d give the name as shown above, hence, allowing you to access the properties by objectName.variableNameXYZ…$4$@@$4$
    $4$@@$4$
    Lastly, to import a default variable:$4$@@$4$
    >>$4$@@$4$
    import variable/functionName from ‘./filename.js’; $4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    The variable/.functionName is a name, that you give to your default import, so here you are assigning a name to the import rather than giving the name of the import you are looking for. $4$@@$4$
    $4$@@$4$
    You can do that since the default does not have a name and the program knows that it is looking for the default ( since you are not giving a name to look for, but only one to assign to ). In this case, you also don’t need to add {}s, as you did before.
    `)],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default SUSSY;
  