import { Route, Routes } from 'react-router-dom';

import { LoadedContextProvider } from './context/3dModelLoadContext/3dModelLoadContext';

// import MyWork from './pages/MyWork/MyWork';
// import About from './pages/About/About';
// import Blog from './pages/Blog/Blog';
// import Contact from './pages/Contact/Contact';

// import HomeCopy from './pages/Home/HomeCopy';

import { lazy, Suspense,useState,useEffect } from 'react';

// import HangmanApp from './projects/hangman/HangmanApp'
// import CalAndMacrosCalcApp from './projects/CalAndMacCalc/CalAndMacrosCalcApp';

import LoadingCurtainStatic from './components/LoadingCurtainStatic';
// import LoadingCurtainExpand from './components/LoadingCurtainExpand';
// import LoadingCurtainCollapse from './components/LoadingCurtainCollapse';
import Home from './pages/Home/Home';
import HomeCopy from './pages/Home/HomeCopy';


// const LazyWideHomeImport = lazy(()=> import("./pages/Home/Home"))
// const LazyNarrowHomeImport = lazy(()=> import("./pages/Home/HomeCopy"))


const LazyMyWork = lazy(()=> import("./pages/MyWork/MyWork"))
const LazyContact = lazy(()=> import("./pages/Contact/Contact"))
const LazyBlog = lazy(()=> import("./pages/Blog/Blog"))
const LazyAbout = lazy(()=> import("./pages/About/About"))

// const LazyHangman = lazy(()=> import("./projects/hangman/HangmanApp"))

const LazyFoodStatsAppCarbs = lazy(()=> import("./projects/DataVisualisation/FoodStatsApp"))
const LazyFoodStatsAppFats = lazy(()=> import("./projects/DataVisualisation/FoodStatsApp2"))

const LazyCalAndMacrosCalcApp = lazy(()=> import("./projects/CalAndMacCalc/CalAndMacrosCalcApp"))

let blogBoxTitlesARRANGED_IN_FILE_ORDER = {
    javaScriptAndProgrammingFoundations: [
                                        "Arrays",
                                        "Boolean Values and Comparison Operators",
                                        "Functions",
                                        "If/If Else/If Else If Statements",
                                        "JavaScript Objects",
                                        "Logical Operators",
                                        "Loops",
                                        "Scopes",
                                        "String Syntax",
                                        "Strings VS Arrays",
                                        "Switch Statements",
                                        "Ternary/Conditional Operator",
                                        "Variables, Data Types and Values",


                                          "Recursions",                                  
                                          ],
    
    javaScriptES6: [
                    "Array Reassignment VS Array Mutability",
                    "Arrow Function Syntax",
                    "Callback Functions",
                    "Classes and Constructor Functions",
                    "Creating a Module Script",
                    "Creating Strings Using Template Literals",
                    "Default Function Parameters",
                    "Destructuring Assignment",
                    "Exporting and Importing",
                    "Getters and Setters",
                    "Promises",
                    "Rest and Spread Operators",
                    "Var vs Let Scope",
                    "Writing Concise Declarative Functions",
                    "Writing Concise Object Literals",
                   ],
  
    debugging: [
                "Common Bugs",
                "Useful Commands For Bug-Checking/Debugging",
                
              ],
  
    basicJavaScriptDataStructures: [
                                    "Arrays Further Concepts",
                                    "Objects Further Concepts"
    ],

    objectOrientedProgramming: [
        "Adding Multiple Prototype Properties at Once",
        "Closure",
        "Constructor Functions and the this Keyword",
        "Functions Within Objects Methods",
        "Immediately Invoked Function Expression IIFE",
        "Instances",
        "Mixin",
        "Modules",
        "Own Constructor And Prototype Properties",
        "Pass by Value VS Pass by Reference",
        "The Prototype Chain - Further Uses",
        "The Prototype Chain",
        "The this Keyword", 
    ],
    functionalProgramming: [
      "Base Concepts of Functional Programming",
      "Currying and Partial Application"
    ]
  }


  // function createJavaScriptAndProgrammingFoundationsBlogComponents(r) {
  //   return r.keys().map((fileName) => {
  //     const Component = lazy(() => import(`./pages/Blog/components/BlogPages/JavaScriptAndProgrammingFoundations${fileName.slice(1)}`));
  //     return () => (
  //       <Suspense fallback={<div>Loading...</div>}>
  //         <Component />
  //       </Suspense>
  //     );
  //   });
  // }

  //JavaScriptAndProgrammingFoundations START

function createJavaScriptAndProgrammingFoundationsBlogComponents(r) {
    return r.keys().map((fileName) => {
      const Component = lazy(() => import(`./pages/Blog/components/BlogPages/JavaScriptAndProgrammingFoundations${fileName.slice(1)}`));
      return () => (
      
          <Component />
      
      );
    });
  }
  
  const javaScriptAndProgrammingFoundationsBlogComponents = createJavaScriptAndProgrammingFoundationsBlogComponents(
    require.context(`./pages/Blog/components/BlogPages/JavaScriptAndProgrammingFoundations`, false, /\.js$/)
  );
  //JavaScriptAndProgrammingFoundations END

  //JavaScriptES6 START

function createJavaScriptES6BlogComponents(r) {
    return r.keys().map((fileName) => {
      const Component = lazy(() => import(`./pages/Blog/components/BlogPages/JS-ES6${fileName.slice(1)}`));
      return () => (
       
          <Component />
      
      );
    });
  }
  
  const javaScriptES6BlogComponents = createJavaScriptES6BlogComponents(
    require.context(`./pages/Blog/components/BlogPages/JS-ES6`, false, /\.js$/)
  );
  //JavaScriptES6 END


//DEBUGGING START

function createDebuggingBlogComponents(r) {
  return r.keys().map((fileName) => {
    const Component = lazy(() => import(`./pages/Blog/components/BlogPages/Debugging${fileName.slice(1)}`));
    return () => (
     
        <Component />
     
    );
  });
}

const debuggingBlogComponents = createDebuggingBlogComponents(
  require.context(`./pages/Blog/components/BlogPages/Debugging`, false, /\.js$/)
);
//DEBUGGING END

//BasicJavaScriptDataStructures START

function createBasicJavaScriptDataStructuresBlogComponents(r) {
    return r.keys().map((fileName) => {
      const Component = lazy(() => import(`./pages/Blog/components/BlogPages/BasicJavaScriptDataStructures${fileName.slice(1)}`));
      return () => (
       
          <Component />
       
      );
    });
  }
  
  const basicJavaScriptDataStructuresBlogComponents = createBasicJavaScriptDataStructuresBlogComponents(
    require.context(`./pages/Blog/components/BlogPages/BasicJavaScriptDataStructures`, false, /\.js$/)
  );
  //BasicJavaScriptDataStructures END

//ObjectOrientedProgramming START

function createObjectOrientedProgrammingBlogComponents(r) {
    return r.keys().map((fileName) => {
      const Component = lazy(() => import(`./pages/Blog/components/BlogPages/ObjectOrientedProgramming${fileName.slice(1)}`));
      return () => (
       
          <Component />
        
      );
    });
  }
  
  const objectOrientedProgrammingBlogComponents = createObjectOrientedProgrammingBlogComponents(
    require.context(`./pages/Blog/components/BlogPages/ObjectOrientedProgramming`, false, /\.js$/)
  );
  //ObjectOrientedProgramming END

  //FunctionalProgramming START

function createBasicJFunctionalProgrammingBlogComponents(r) {
    return r.keys().map((fileName) => {
      const Component = lazy(() => import(`./pages/Blog/components/BlogPages/FunctionalProgramming${fileName.slice(1)}`));
      return () => (
        
          <Component />
        
      );
    });
  }
  
  const functionalProgrammingBlogComponents = createBasicJFunctionalProgrammingBlogComponents(
    require.context(`./pages/Blog/components/BlogPages/FunctionalProgramming`, false, /\.js$/)
  );
  //FunctionalProgramming END


function AppRoutes() {

  const [viewportWidth,setViewportWidth] = useState(window.innerWidth)

  useEffect(()=>{

    const handleResize = () => {
      setViewportWidth(window.innerWidth)
  }

  window.addEventListener('resize', handleResize)

  return () => {
    
    window.removeEventListener('resize', handleResize)
  }
  
  
  },[])
  return (
    <>
    <Routes>
    <Route path='/' element={viewportWidth < 1200 ? <LoadedContextProvider><HomeCopy /></LoadedContextProvider> : <LoadedContextProvider><Home /></LoadedContextProvider>}/>
    </Routes>
    <Suspense fallback={<LoadingCurtainStatic />}>
    <Routes>
      {/* <Route path='/' element={viewportWidth < 1200 ? <LazyNarrowHomeImport /> : <LazyWideHomeImport />}/> */}
        <Route path='/my-work' element={<LoadedContextProvider><LazyMyWork/></LoadedContextProvider>}/>
        <Route path='/about' element={<LazyAbout/>}/>
        <Route path='/blog' element={<LazyBlog/>}/>
        <Route path='/contact' element={<LazyContact/>}/>
        {/* <Route path='/apps/hangman' element={<LazyHangman />} /> */}
        <Route path='/apps/data-visualisation-app/carbohydrates' element={<LazyFoodStatsAppCarbs />} />
        <Route path='/apps/data-visualisation-app/fats' element={<LazyFoodStatsAppFats />} />
        <Route path='/apps/calorie-and-macronutrients-calculator' element={<LazyCalAndMacrosCalcApp />} />
        

        {javaScriptAndProgrammingFoundationsBlogComponents.map((Component, index) => (
        <Route
          key={index}
          path={`/blog/${blogBoxTitlesARRANGED_IN_FILE_ORDER.javaScriptAndProgrammingFoundations[index].toLowerCase().replace(',','').replace('/', '-').split(' '). join('-')}`}
          element={<Component />}
        />
      ))}
      {javaScriptES6BlogComponents.map((Component, index) => (
        <Route
          key={index}
          path={`/blog/${blogBoxTitlesARRANGED_IN_FILE_ORDER.javaScriptES6[index].toLowerCase().replace(',','').replace('/', '-').split(' '). join('-')}`}
          element={<Component />}
        />
      ))}
      {debuggingBlogComponents.map((Component, index) => (
        <Route
          key={index}
          path={`/blog/${blogBoxTitlesARRANGED_IN_FILE_ORDER.debugging[index].toLowerCase().replace(',','').replace('/', '-').split(' '). join('-')}`}
          element={<Component />}
        />
      ))}
      {basicJavaScriptDataStructuresBlogComponents.map((Component, index) => (
        <Route
          key={index}
          path={`/blog/${blogBoxTitlesARRANGED_IN_FILE_ORDER.basicJavaScriptDataStructures[index].toLowerCase().replace(',','').replace('/', '-').split(' '). join('-')}`}
          element={<Component />}
        />
      ))}
      {objectOrientedProgrammingBlogComponents.map((Component, index) => (
        <Route
          key={index}
          path={`/blog/${blogBoxTitlesARRANGED_IN_FILE_ORDER.objectOrientedProgramming[index].toLowerCase().replace(',','').replace('/', '-').split(' '). join('-')}`}
          element={<Component />}
        />
      ))}
      {functionalProgrammingBlogComponents.map((Component, index) => (
        <Route
          key={index}
          path={`/blog/${blogBoxTitlesARRANGED_IN_FILE_ORDER.functionalProgramming[index].toLowerCase().replace(',','').replace('/', '-').split(' '). join('-')}`}
          element={<Component />}
        />
      ))}
      
    </Routes>
    </Suspense>

   </>

  );
}

export default AppRoutes