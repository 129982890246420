import './components/RotatingObjects.scss';
import { Canvas } from 'react-three-fiber';

function RotObjCopy(props) {
    return (
      <div 
        className='sus' >
        <Canvas className='susCanvas'>
        {props.children}
        </Canvas>
      </div>
     
    );
  }

  export default RotObjCopy;
  
