import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Constructor_Functions_And_The_This_Keyword() {
  const title = 'Constructors And The This Keyword'

  const paragraphs = {

    first: [insertNewLines(`As you know, constructor functions, are functions that create new objects.$4$@@$4$
$4$@@$4$
    They do this by setting the properties and behaviours of the new objects and when called, they create an object of the given name, with the properties which had been set within the constructor.$4$@@$4$
    For example:$4$@@$4$
    >>$4$@@$4$
    function Baby( )  {   //remember, constructors are capitalized(standard practice)$4$@@$4$
    $4$@@$4$
    $4$%%$4$this.name = “Alex”;$4$@@$4$
    $4$%%$4$this[‘makes sounds’] = ‘gugu gaga’;$4$@@$4$
    $4$%%$4$this.saySounds = function( ) { return ${'`${'}this.name} makes ${'${'}this[“makes sounds”]} sounds.${'`'} };$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    Now, we have a constructor function, which, can be used to create objects with the given properties.$4$@@$4$
    Note a couple things:$4$@@$4$
    This is a function, not an object, and so, when giving the values of the keys – and equal sign = is used, instead of a colon :$4$@@$4$
    The this keyword, would be used to refer to the name, of the newly generated object, using the constructor$4$@@$4$
    There is no return keyword, where the given properties would be returned to the given variable when the constructor is called. In constructor functions, you don’t need return keywords, instead you just need to give the properties and behaviours of the object, which you want to be created. $4$@@$4$
    The constructor function needs to be called with the *new* keyword$4$@@$4$
    $4$@@$4$
    Here is an example of an object creation, using the Baby constructor:$4$@@$4$
    >>$4$@@$4$
    let baby1 = new Baby( );$4$@@$4$
    <<$4$@@$4$
    This will create a new object, called baby1, with all the properties and behaviours given in the Baby constructor function. $4$@@$4$
    $4$@@$4$
    This object will function just any regular object, where the values of the properties can be accessed by objectName.propertyName…$4$@@$4$
    $4$@@$4$
    NOW, this allows you to create multiple objects, with the same properties, WITH THE OPTION of still changing SOME of the properties.$4$@@$4$
    
    To put this into practice, let’s say that we have a database with 100 babies. They may all make ‘gugu gaga’ sounds, but not all of them may be with the same name, age, or other characteristics, so here’s an improved Baby constructor to address that:$4$@@$4$
    >>$4$@@$4$
    function Baby(name, age)  {  $4$@@$4$
    $4$@@$4$
    $4$%%$4$this.name = name;$4$@@$4$
    $4$%%$4$this.age = age;$4$@@$4$
    $4$%%$4$this[‘makes sounds’] = ‘gugu gaga’;$4$@@$4$
    $4$%%$4$this.saySounds = function( ) { return ${'`${'}this.name} makes ${'${'}this[“makes sounds”]} sounds.${'`'} };$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    We can now create new objects using the Baby constructor, and give those new objects custom name and age property values, by inputting the desired values as arguments:$4$@@$4$
    >>$4$@@$4$
    let baby2 = new Baby(“Ugundi”, 2);$4$@@$4$
    <<$4$@@$4$
    Now, we will have a new object, baby2, which will have the baby2.name key == “Ugundi” and baby2.age key == 2 – while still retaining the other properties.$4$@@$4$
    $4$@@$4$
    When combined with the ${'${'}this.name} and ${'${'}this[‘makes sounds’]} in the saySounds function, it means that each new object using the Baby constructor, will also have a custom message when calling it’s 
    saySounds( ) function
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Constructor_Functions_And_The_This_Keyword;
  