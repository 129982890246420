import { useState } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Arrays_Further_Concepts() {
  const title = 'Arrays Further Concepts'

  const paragraphs = {

    first: [insertNewLines(`
    I - Items in an array can be removed by the .splice( ) method,$4$@@$4$
$4$@@$4$
$4$@@$4$
    The method takes 2 or 3 arguments:$4$@@$4$
    1st argument – refers to the index (starting from 0) of the element, from which, you want deletion to begin.$4$@@$4$
    $4$@@$4$
    2nd argument – refers to the AMOUNT of elements, that you would want to remove, where the counting includes the element at the position given in the first argument $4$@@$4$
    $4$@@$4$
    3rd argument – which is optional, refers to an element, which you would like to put in place of the removed elements, where the element is inserted at the position at which deletion began$4$@@$4$
    $4$@@$4$
    For Example:$4$@@$4$
    >>$4$@@$4$
    let arr1 = [1,2,3,4,5];$4$@@$4$
    arr1.splice(1,2);$4$@@$4$
    <<$4$@@$4$
    Here, deletion begins at index[1] – so from the element – number 2. Then, the second argument is 2, which means that 2 items are to be removed. Since counting includes the element at the index of the first argument, it means that the elements 2 and 3 will be removed.$4$@@$4$
    >>$4$@@$4$
    let arr2 = [‘yes’, ‘no’];$4$@@$4$
    arr2.splice(0, 2, ’maybe’);$4$@@$4$
    <<$4$@@$4$
    here, arr2 will end up with a value of [‘maybe’] – as 2 elements will be removed, starting from the index[0] – so the elements ‘yes’ and ‘no’ will be removed, and ‘maybe’ will be put in place at index[0]$4$@@$4$
    $4$@@$4$
    Lastly, the .splice( ) method “takes” (returns), the values of the spliced elements.$4$@@$4$
    For example:$4$@@$4$
    >>$4$@@$4$
    let arr3 = [“x”, “y”, “z”];$4$@@$4$
    let variable1 = arr3.splice(1,1);$4$@@$4$
    <<$4$@@$4$
    here, y will be removed from arr3, and then it will be returned to variable1, so arr3 == [“x”, “z”];$4$@@$4$
    and variable1 == “y”;$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    II - Elements can be extracted/copied with the .slice( ) method,$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    The method takes 2 arguments:$4$@@$4$
    1st argument – refers to the index at which the extraction starts$4$@@$4$
    $4$@@$4$
    2nd argument – refers to the index at which the extraction ends$4$@@$4$
    $4$@@$4$
    copying INCLUDES the FIRST given index, but EXCLUDES, the last given index$4$@@$4$
    An example of the .slice( ) method:$4$@@$4$
    >>$4$@@$4$
    let arr1 = [1,2,3,4,5];$4$@@$4$
    let arr2 = arr1.slice(2,4);$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    Here, arr2 == [3,4]$4$@@$4$
    $4$@@$4$
    The index/position of an element can be checked by using the. indexOf( ) method,$4$@@$4$
    You can use the method, by giving the element whose position you need as an argument of the method. $4$@@$4$
    Hence, if the element you give DOES EXIST, its index will be returned as a number.$4$@@$4$
    But if the element you give DOES NOT EXIST, the number -1 will be returned instead – so the method can be used to check if an element exists in general (since if it doesn’t, -1 will be returned).$4$@@$4$
    $4$@@$4$
    For example:$4$@@$4$
    >>$4$@@$4$
    let arr1 = [1,2,3,4,5]$4$@@$4$
    $4$@@$4$
    console.log(arr1.indexOf(3));$4$@@$4$
    console.log(arr1.indexOf(“hahaFunny”));$4$@@$4$
    <<$4$@@$4$
    The console will display 2, -1 => this is because 3 is at index 2, so 2 is the value returned from the first console.log, but “hahaFunny” doesn’t exist as an element within the array, so -1 is returned.
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  
  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Arrays_Further_Concepts;
  