

import './RotatingObjects.scss';

import { Canvas } from 'react-three-fiber';



function RotatingObjects(props) {
  
    return (
      <div className='sus' >
        <Canvas >
          <pointLight 
            color='red'
            intensity={0.25}
            position={[0,0,1]}
          />

          <spotLight 
            color={[0.14, 0.5, 1]}
            intensity={2}
            angle={0.6}
            penumbra={0.5}
            position={[5,5,5]}
          />

          <spotLight 
            color={[1, 0.25, 0.7]}
            intensity={0.8}
            angle={0.6}
            penumbra={0.6}
            position={[0,0,5]}
          />

          <spotLight 
            color={[1, 0.25, 0.7]}
            intensity={1}
            angle={0.6}
            penumbra={0.5}
            position={[40,0,5]}
            />

          <spotLight 
            color={[1, 0.25, 0.7]}
            intensity={1}
            angle={0.6}
            penumbra={0.5}
            position={[-40,0,5]}
          />

        {props.children}
        
      </Canvas>
      </div>
     
    );
  }
  
  export default RotatingObjects;
  


