import './LoadingCurtain.css'

function LoadingCurtainStatic() {
  
  return (
    <div className='LoadingCurtainStatic'>
        <h2>LOADING</h2>
    </div> 
  );
}

export default LoadingCurtainStatic;
