
import './Home.scss';
import { useRef, useState, useEffect, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { PerspectiveCamera } from '@react-three/drei';

import RotObjCopy from './RotObjCopy';

import CreateBlocks from './components/CreateBlocks';

import { Home3dSmallScreenModel } from '../../3dModels2/ModelJSFiles/Home3dSmallScreenModel';

import { useLoadedContext } from '../../context/3dModelLoadContext/3dModelLoadContext';

// import { useLayoutEffect } from 'react';

import LoadingCurtainCollapse from '../../components/LoadingCurtainCollapse';
import LoadingCurtainExpand from '../../components/LoadingCurtainExpand';
import LoadingCurtainStatic from '../../components/LoadingCurtainStatic';



function HomeCopy() {
  
  const [stop, setStop] = useState(false)
  
  const[appear, setAppear] = useState(-1)

  const handleMouseEnter = useCallback(() =>{
    setStop(true)
  },[stop])
  const handleMouseLeave = useCallback(() =>{
    setStop(false)
  },[stop])


  const loading = useLoadedContext()
  
  useEffect(()=>{

    if(loading){
      setTimeout(()=>{
        setAppear(0)
        
      },900)
      setTimeout(()=>{
        setAppear(1)
        
      },1300)
    }
    
  },[loading])

  const navigate = useNavigate()

  const handleClick = (e, destination) => {
    
    
    setAppear(2)
    e.target.style.pointerEvents = 'none'
    setTimeout(() => {
      navigate(`/${destination}`);
    }, 1300); 
  
  
}
  const cameraRef = useRef()
  useEffect(()=>{
    
    function handleMove(event) {
      const screenHeight = window.innerHeight;
      const excludeHeight = screenHeight * 0.1;
      if(!stop){
        const mouseX = event.touches ? event.touches[0].clientX / window.innerWidth : event.clientX / window.innerWidth
        const mouseY = event.touches ? event.touches[0].clientY / window.innerHeight : event.clientY / window.innerHeight
      
      if(mouseY > 0.12 && loading === true){
        cameraRef.current.rotation.y = mouseX * 0.05
        cameraRef.current.rotation.x = mouseY * 0.05
      }
      // console.log(cameraRef.current.rotation.y)
      }
      
    }

    window.addEventListener('mousemove', handleMove)
    window.addEventListener('touchmove', handleMove)
    
    return () => {
      window.removeEventListener('mousemove', handleMove)
      window.removeEventListener('touchmove', handleMove)
    }

    
  },[cameraRef, stop, loading])


  return (
    <div className="Home" >  
     {loading ? <LoadingCurtainExpand appear={appear}/> : <LoadingCurtainStatic />}
    {appear > 1 && <LoadingCurtainCollapse/>}
      
    <div className='pageSelectorContainer'>
      <CreateBlocks 
        numberOfBlocks={20}
        blocksClassName={'projects-blocks'}
        destination={'my-work'}
        handleClick={handleClick}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        appear={appear}
      />

      <CreateBlocks 
        numberOfBlocks={12}
        blocksClassName={'blog-blocks'}
        destination={'blog'}
        handleClick={handleClick}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        appear={appear}
      />

      <CreateBlocks 
        numberOfBlocks={14}
        blocksClassName={'about-blocks'}
        destination={'about'}
        handleClick={handleClick}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        appear={appear}
      />


      <CreateBlocks 
        numberOfBlocks={18}
        blocksClassName={'contact-blocks'}
        destination={'contact'}
        handleClick={handleClick}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        appear={appear}
      />
      
      </div>
      <div className='intro-container'>
        <h1>
          <span className='spans'>T</span>
          <span className='spans'>E</span>
          <span className='spans'>N</span>
          <span className='spans'>E</span>
          <span className='spans'>V</span>
        </h1>
        <h2>
          <span className='spans'>W</span>
          <span className='spans'>E</span>
          <span className='spans'>B</span>
          <span className='spans'>-</span>
          <span className='spans'>D</span>
          <span className='spans'>E</span>
          <span className='spans'>V</span>
        </h2>
      </div>
      <RotObjCopy >       
        <PerspectiveCamera ref={cameraRef} makeDefault  position={[0,0, 30]} />
          <Home3dSmallScreenModel stop={stop} />
        </RotObjCopy>
      
      
    </div>
  );
}

export default HomeCopy;
