import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Array_Reassignment_VS_Array_Mutability() {
  const title = 'Array Reassignment VS Array Mutability'

  const paragraphs = {

    first: [insertNewLines(`Something else which was also mentioned before, was that the declaring variables with const – made variables unable to be REASSIGNED,$4$@@$4$
$4$@@$4$
    for example:$4$@@$4$
    >>$4$@@$4$
    const arr1 = [1,2,3];$4$@@$4$
    const arr2 = [4,5,6];$4$@@$4$
    $4$@@$4$
    arr1 = arr2;$4$@@$4$
    <<$4$@@$4$
    this will throw an error as arr2 is a constant and cannot be reassigned.$4$@@$4$
    HOWEVER – const variables can still be MUTATED, and so individual elements of arrays can still be changed:$4$@@$4$
    >>$4$@@$4$
    arr1[0] = arr2[1]$4$@@$4$
    <<$4$@@$4$
    now arr1 will equal the value of [5,2,3]$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    There is a way, however, to prevent even mutation from occurring and this would be done by the Object.freeze() function$4$@@$4$
    $4$@@$4$
    Here’s an example(following the arrays from above):$4$@@$4$
    >>$4$@@$4$
    Object.freeze(arr2);$4$@@$4$
    $4$@@$4$
    arr2[0] = 5;$4$@@$4$
    <<$4$@@$4$
    here, the console will throw an error as arr2 has been frozen and yet we tried to mutate it. $4$@@$4$
    $4$@@$4$
    The Object.freeze() function also works with objects, where when an object is frozen, upon trying to change its properties, an error will be given.
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Array_Reassignment_VS_Array_Mutability;
  