import React, { useState, useContext } from 'react'

const LoadedContext = React.createContext()
const LoadedStateChangeContext = React.createContext()


export function useLoadedContext(){
    return useContext(LoadedContext)
}

export function useLoadedStateChangeContext(){
    return useContext(LoadedStateChangeContext)
}


export function LoadedContextProvider({ children }){
    const [loaded, setLoaded] = useState(false)

    function changeLoadStatus (){
        setLoaded(true)
    }

    return (
        <LoadedContext.Provider value={loaded}>
            <LoadedStateChangeContext.Provider value={changeLoadStatus}>
                {children}
            </LoadedStateChangeContext.Provider>
        </LoadedContext.Provider>
    )
}