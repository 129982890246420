import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Arrow_Function_Syntax() {
  const title = 'Arrow Function Syntax'

  const paragraphs = {

    first: [insertNewLines(`You can create functions quicker/more efficiently by using the “arrow” syntax.$4$@@$4$
$4$@@$4$
    So far, we have been creating functions using the following syntax:$4$@@$4$
    >>$4$@@$4$
    function functionName( ) {…code…}$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    While there isn’t anything wrong with it, functions can be written in a more concise manner, let’s look at a scenario, where we create a function, by storing a function into a variable:$4$@@$4$
    >$4$@@$4$
    const myFunction = function( ) {…code…};$4$@@$4$
    <$4$@@$4$
    in this example, the const myFunction stores a given function, calling the variable with myFunction(), will invoke the function stored within it.$4$@@$4$
    $4$@@$4$
    Following this method, ES6 provides a quicker way to write functions in the following way:$4$@@$4$
    >$4$@@$4$
    const functionName = ( ) => {…code…};$4$@@$4$
    <$4$@@$4$
    this syntax does the exact same thing as the syntax just before, but as you can see, the function keyword is cut and hence makes it more concise.$4$@@$4$
    $4$@@$4$
    This is especially useful when you don’t need a function body, as in this case, the syntax just returns whatever is after the arrow, so then, you wouldn’t even need to write a return keyword in the code block:$4$@@$4$
    >>$4$@@$4$
    const myFunc = ( ) => “I am returned”;$4$@@$4$
    <<$4$@@$4$
    since there is no body, you can both avoid the curly brackets and the return keyword, and the function will return the given single value (the “I am returned” string in this case)$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    Lastly, if you have a single parameter, you can also avoid adding in the ()s, so long as it is only a single parameter (multiple parameters still require ()s). Combining this with a case of no function body, this syntax becomes much more handy:$4$@@$4$
    >>$4$@@$4$
    const myFuncX = item => item + 2;$4$@@$4$
    <<$4$@@$4$
    this will add 2 to the given argument and return the result.$4$@@$4$
    $4$@@$4$
    As you can see, this shortens the former way in which we would write such a function, just to show as comparison:$4$@@$4$
    >> $4$@@$4$
    function myFuncX(item) {return item +2;}$4$@@$4$
    <<$4$@@$4$
    
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Arrow_Function_Syntax;
  