import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Var_VS_Let_Scope() {
  const title = 'Var VS Let Scope'

  const paragraphs = {

    first: [insertNewLines(`The let and const keywords are keywords which were introduced in ES6.$4$@@$4$
    Previously, we spoke about function scope and how variables declared outside a function, are global, whereas if declared within a function, their scope will be bound to the function.$4$@@$4$
    $4$@@$4$
    That is fully the case for var declaration, what wasn’t mentioned however, is that let keywords have a scope one step more specialized than var keywords, and that is a BLOCK scope.$4$@@$4$
    $4$@@$4$
    What that means, variables declared with let, will have a scope not only limited to the function, but to the block they are declared in – WHEREAS, var declarations, even when declared in a block, they will still be visible to the whole function – or globally if not declared in a function.$4$@@$4$
    $4$@@$4$
    To show that:$4$@@$4$
    >>$4$@@$4$
    function functionName(){$4$@@$4$
    $4$%%$4$var varVariable = 1;$4$@@$4$
    $4$%%$4$let letVariable = 1;$4$@@$4$
    }$4$@@$4$
    let a = varVariable;$4$@@$4$
    let b = letVariable;$4$@@$4$ 
    <<$4$@@$4$
    The program will throw an error, as both var and let are declared inside the function, therefore they are only visible from the function and they will be considered undefined outside – this is what we expect so far, if we want for them to be visible, the a and b variable, will have to be in the function:$4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    function functionName(){$4$@@$4$
    $4$%%$4$var varVariable = 1;$4$@@$4$
    $4$%%$4$let letVariable = 1;$4$@@$4$
    $4$@@$4$
    $4$%%$4$let a = varVariable;
    $4$%%$4$let b = letVariable;
    }$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    HOWEVER:$4$@@$4$
    >>$4$@@$4$
    function functionName(){$4$@@$4$
    $4$%%$4$for(let incrementor = 0; incrementor < 1; incrementor++){$4$@@$4$
    $4$%%$4$$4$%%$4$var varVariable = 1;$4$@@$4$
    $4$%%$4$$4$%%$4$let letVariable = 1;$4$@@$4$
    $4$%%$4$}$4$@@$4$
    $4$@@$4$
    $4$%%$4$let a = varVariable;$4$@@$4$
    $4$%%$4$let b = letVariable;$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    NOW, we have declared varVariable and letVariable in a BLOCK – in this case, the block is the for loop.$4$@@$4$
    $4$@@$4$
    NOW – a will still = varVariable, but b will give a reference error as b is undefined, this shows how var variables have a function scope at most, and even when declared in to a block, they will still be visible in the whole function (or globally if declared outside a function), whereas the let variables have a BLOCK scope, so when declared in a block, they will only be visible in the block in which they are declared.$4$@@$4$
    $4$@@$4$
    This also means that you can declare variables with the same names, so long as their scopes don’t interfere, do have two variables with the same name, but different values.$4$@@$4$
    for example:$4$@@$4$
    >>$4$@@$4$
    function scopeConfusion( ){$4$@@$4$
    $4$%%$4$let i = 1;$4$@@$4$
    $4$@@$4$
    $4$%%$4$if(true){$4$@@$4$
    $4$%%$4$$4$%%$4$let i = 2;$4$@@$4$
    $4$%%$4$$4$%%$4$let x = i;$4$@@$4$
    $4$%%$4$}$4$@@$4$
    $4$@@$4$
    $4$%%$4$let y = i;$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    in this case, both x and y  = i, but x is declared within the true statement , so the value of x (and x is only visible within its block too, but can be visible in the whole function by changed to a var instead of let) equals 2, whereas the value of y equals 1, because both x and y take the value of i from the value of i visible in its respective block. $4$@@$4$
    $4$@@$4$
    DO NOT CONFUSE HOWEVER – when i = 1, it is still visible in the if block, because the if block is inside the function block, it is on a lower level so to say.$4$@@$4$
    But inside the if block, i is REDECLARED(there is a new let, not just a change of the value by i =2 – which would be REASSIGNING the value of i), which this is NOT visible in the function block, as it is OUTSIDE of the if block(if in the if block, i is reassigned to i = 2, rather than a redeclaration of let i = 2, then both x and y will equal 2).`)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Var_VS_Let_Scope;
  