import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Destructurung_Assignment() {
  const title = 'Destructurung Assignment'

  const paragraphs = {

    first: [insertNewLines(`ES6 adds a better way to add properties to an object, by using the DESTRUCTURING ASSIGNMENT$4$@@$4$
$4$@@$4$
    Firstly, let’s say that we want to create a variable, which holds an object’s property, and that property has the same name as the variable, for example:$4$@@$4$
    >>$4$@@$4$
    let dog = {$4$@@$4$
    $4$%%$4$breed : “Husky”,$4$@@$4$
    $4$%%$4$colour : “black and white”,$4$@@$4$
    $4$%%$4$legs : 4$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    const breed = dog.breed;$4$@@$4$
    const colour = dog.colour;$4$@@$4$
    <<$4$@@$4$
    here, our breed variable will hold the same value as dog.breed and the color variable will = dog.colour…but this can be done in a better way with the destructuring assignment:$4$@@$4$
    >>$4$@@$4$
    const {breed, colour} = dog;$4$@@$4$
    <<$4$@@$4$
    this syntax will act just like the declarations above, to create two new variables, which will have values equal to the property in dog, which holds the same name as them – you can put as many or as little variables as you want$4$@@$4$
    $4$@@$4$
    You can also use destructuring to create variables with DIFFERENT names, to the name of the property which is assigned to them:$4$@@$4$
    >>$4$@@$4$
    const {legs : legNumber} = dog;$4$@@$4$
    <<$4$@@$4$
    this will create a new variable, called legNumber, which will hold the value of the of the dog.legs property. Just like before, you can simultaneously create as many variables as you want this way (separating each with coma)$4$@@$4$
$4$@@$4$   
 $4$@@$4$
    Now, using the same 2 previous principles, you can go on to extract properties from nested objects in the following way(using the dog object):$4$@@$4$
    >>$4$@@$4$
    let dog = {$4$@@$4$
    $4$%%$4$breed : “Husky”,$4$@@$4$
    $4$%%$4$colour : “black and white”,$4$@@$4$
    $4$%%$4$legs : 4$4$@@$4$
    $4$%%$4$likesFoods: {$4$@@$4$
    $4$%%$4$$4$%%$4$                   fish: “yes”, $4$@@$4$
    $4$%%$4$$4$%%$4$                   chicken: “yes”, $4$@@$4$
    $4$%%$4$$4$%%$4$                   garlic: “no”$4$@@$4$
    $4$%%$4$                  }$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    const {likesFoods :  {fish, chicken} } = dog;$4$@@$4$
    <<$4$@@$4$
    the above destructuring assignment will create 2 new variables, called fish and chicken, which will have the dog.likesFoods.fish and dog.likesFoods.chicken values, respectively (both “yes”)$4$@@$4$
    $4$@@$4$
    And to create variables with different names:$4$@@$4$
    >>$4$@@$4$
    const {likesFoods: { garlic : doesTheDogLikeGarlic }} =dog;$4$@@$4$
    <<$4$@@$4$
    this will, just as before, create a new variable called doesTheDogLikeGarlic, with the value of the dog.likesFoods.garlic value (“no”).$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    One more useful logic for destructuring objects, is to use the destructure assignment to pass an object as a function’s parameters, $4$@@$4$
    $4$@@$4$
    What this means, is that by destructuring, when you call  function, with the input(the argument) of the wanted object, you will be able to use the object’s properties as variables within the function, for example:$4$@@$4$
    >>$4$@@$4$
    const clientDogData = ({breed, colour, legs}) => “Our “ + breed + “ is “ + colour + “ and has “ legs “legs.”$4$@@$4$
    $4$@@$4$
    let clientMessage = clientDogData(dog);$4$@@$4$
    <<$4$@@$4$
    here, the clientDogData is a function which returns the string “Our breed is  colour and has  legs  legs.”, where the underlined variables breed, colour, and legs are the DIRECT NAMES OF THE PROPERTIES OF THE OBJECT GIVEN AS AN ARGUMENT INTO THE FUNCTION CALL, so when the object dog is used as the argument, the dog.breed, dog.colour and dog.legs, so clientMessage = Our Husky is black and white and has 4 legs.$4$@@$4$
    $4$@@$4$
    So really, this is a short way of writing:$4$@@$4$
    >>$4$@@$4$
    const clientDogData = (object) => {$4$@@$4$
    $4$%%$4$const {breed, color, legs} = object;$4$@@$4$
    $4$%%$4$return “Our “ + breed + “ is “ + colour + “ and has “ legs “legs.”$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    where, as you can see, the object is used as a parameter, and then the given properties are destructured into variables, in the function, after which, these variables can be freely used.$4$@@$4$
    Just to avoid confusion, this method can be used by both the arrow and normal function syntax$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    LASTLY, arrays can also be destructured, in the following way:$4$@@$4$
    >>$4$@@$4$
    const [variable1] = [1,2,3,4,5];$4$@@$4$
    <<$4$@@$4$
    this will create a variable – variable1, which has the value of 1 – this is the value of the index 0, to have the value of different indexes, simply add commas:
    >>
    const [,,,,variable2] = [1,2,3,4,5];
    <<
    here, variable2 = 5, as every comma before it, represents an index skipped, lastly, you can destructure multiple variables at the same time:$4$@@$4$
    >>$4$@@$4$
    const [ variable3,,variable4,variable5] = [1,2,3,4,5];$4$@@$4$
    <<$4$@@$4$
    here, variable3 = 1, variabe4 = 3, variable5 = 5
    $4$@@$4$
    $4$@@$4$
    Destructuring arrays can be very powerful and used to swap variables’ variables simultaneously, without creating an intermediate as in:$4$@@$4$
    >>$4$@@$4$
    let a = 1;$4$@@$4$
    let b = 2;$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    To swap with intermediate:$4$@@$4$
    >>$4$@@$4$
    let c = a;$4$@@$4$
    a = b;$4$@@$4$
    b = c;$4$@@$4$
    <<$4$@@$4$
    if we just create a = b, the value of a will be lost and then we will have 2 variables, with the same value, so we need to include a 3rd temporary value, c in which to hold one of them, in this case a, and when we switch a to be, its value isn’t lost$4$@@$4$
    $4$@@$4$
    with destructuring of arrays however:$4$@@$4$
    >>$4$@@$4$
    let [a,b] = [b,a];$4$@@$4$
    <<$4$@@$4$
    and just like that, in one line, rather than 3, we swapped the values of a and b`)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Destructurung_Assignment;
  