import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";
import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Switch_Statements() {
  const title = 'Switch Statements'

  const paragraphs = {

    first: [insertNewLines(`A switch statement sets a value, against which, a value within case statements is compared. If the value within the switch statemenT equals the value of the case statementS, the code within case statements is executed$4$@@$4$
    A break command follows each case statement, so that, when a certain case is met and its code is executed, the program can stop, which will stop any further unnecessary processing.$4$@@$4$
    There is no limit to the number of case statements…$4$@@$4$
    $4$@@$4$
    Example of switch statements syntax:$4$@@$4$
    >>$4$@@$4$
    let variable1 = “c”;$4$@@$4$
    let variable2;$4$@@$4$
    $4$@@$4$
    switch(variable1) {$4$@@$4$
    $4$%%$4$    case ‘a’ :$4$@@$4$
    $4$%%$4$$4$%%$4$        variable2 = “Apples”;$4$@@$4$
    $4$%%$4$$4$%%$4$        break;$4$@@$4$
    $4$%%$4$    case ‘b’ :$4$@@$4$
    $4$%%$4$$4$%%$4$        variable2 = “Booby”;$4$@@$4$
    $4$%%$4$$4$%%$4$         break;$4$@@$4$
    $4$%%$4$    case ‘c’ :$4$@@$4$
    $4$%%$4$$4$%%$4$        variable2 = “Candy”;$4$@@$4$
    $4$%%$4$$4$%%$4$        break;$4$@@$4$
    $4$%%$4$    case ‘d’ :$4$@@$4$
    $4$%%$4$$4$%%$4$        variable2 = “Dudu”;$4$@@$4$
    $4$%%$4$$4$%%$4$         break;$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    in the example above, the switch statement sets variable1 for testing. It then presents 4 cases, each testing if variable1 equals a certain character, when a correct case is met, the code which follows is executed.$4$@@$4$
    In the example, variable1 = the string c, so variable 2 is given the value of “Candy”, and when the code is executed, a break is then executed, stopping further checking of the case statements$4$@@$4$
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Switch_Statements;
  