import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";
import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function JavaScript_Objects() {
  const title = 'JavaScript Objects'

  const paragraphs = {

    first: [insertNewLines(`Objects are similar to arrays, but instead of indexes, data is accessed through properties.$4$@@$4$
    Objects can be used to represent real world objects, with qualities.$4$@@$4$
    JavaScript offers a whole object oriented approach when it comes to programming as a whole, but this will be covered later.$4$@@$4$
    $4$@@$4$
    Here’s an example object:$4$@@$4$
    >>$4$@@$4$
    let dog = {$4$@@$4$
    $4$%%$4$“name” : “Bubu”,$4$@@$4$
    $4$%%$4$colour : “brown”,$4$@@$4$
    $4$%%$4$legs : 4$4$@@$4$
    $4$%%$4$preferred foods : [“fish”, “chicken”]$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    as you can see, the syntax follows :$4$@@$4$
    -> declare the variable $4$@@$4$
    -> the object itself is encased in {} brackets$4$@@$4$
    -> then declare properties, where each property has a value and each property is separated by a coma$4$@@$4$
    -> the property names can both be written with or without “”s, as shown the property name – written with “”s, unlike the rest$4$@@$4$
    -> instead of an equal sign, each property is given its value through a semi colon - *:*$4$@@$4$
    -> lastly, the value can be any, and it has to follow its general syntax, such as “”s for strings$4$@@$4$
    $4$@@$4$
    => therefore:$4$@@$4$
    $4$@@$4$
    let objectName = {$4$@@$4$
    $4$%%$4$propertyX : valueX,
    $4$%%$4$property : valueY$4$%%$4$
    …$4$%%$4$
    }`)],

    second: [insertNewLines(`Each property can be ACCESSED, in two ways – dot notation and bracket notation:$4$@@$4$
    $4$@@$4$
    1 - Dot notation:$4$@@$4$
    dot is done by giving the object’s name, followed by a dot and the desired property, for example:$4$@@$4$
    >>$4$@@$4$
    let objectX = {$4$@@$4$
    $4$%%$4$property1 : value1,
    $4$%%$4$property2 : value2$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    let variable1 = objectX.property1;$4$@@$4$
    <<$4$@@$4$
    given the code above, variable1 will be given the value of value1.$4$@@$4$
    $4$@@$4$
    So as you can see, you are accessing the value of the given property.$4$@@$4$
    $4$@@$4$
    Properties and their values are known as KEY-VALUE pairs, as each property is a “key” to its value.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    2 - Bracket notation:$4$@@$4$
    This can be used just as the dot notation, where instead of a dot, you use the desired property, encased in [] brackets, after the object, $4$@@$4$
    HOWEVER – there is another difference as well.$4$@@$4$
    $4$@@$4$
    If accessing the property directly –  as in that you are directly using the name of the object’s property, the name of the given property needs to be encased in “”s, here is what the same code as above would look when accessing with brackets would look:$4$@@$4$
    >>$4$@@$4$
    let variable1 = objectX[“property1”];$4$@@$4$
    <<$4$@@$4$
    this statement will give the exact same result as above$4$@@$4$
    $4$@@$4$
    NEVERTHELESS…as mentioned – the value within a property can be accessed INDIRECTLY, and this is the use of the bracket notation.$4$@@$4$
    If you have a variable, that has the SAME value as the name of the property you want to access, for example (following the object above):$4$@@$4$
    >>$4$@@$4$
    let variable2 = property1;$4$@@$4$
    <<$4$@@$4$
    the variable above doesn’t have the value of property1, but its value is the same as the name of property1 itself, you can then use variable2 to indirectly access the that very property as so:$4$@@$4$
    >>$4$@@$4$
    let variable3 = objectX[variable2];$4$@@$4$
    <<$4$@@$4$
    as you can see, when using a VARIABLE, there AREN’T “”s around it.$4$@@$4$
    But all in all, the variable is like a placeholder for the property’s name and you are doing the same as just using the property’s name.$4$@@$4$
    $4$@@$4$
    As a last point to be made, both bracket and dot notation can be used synchronously together
    `)],

    third: [insertNewLines(`
    Lastly, when it comes to the basics of objects, as you saw, objects can hold any value, that includes an object itself, just like how arrays can hold arrays within arrays, for example:$4$@@$4$
    >>$4$@@$4$
    let objectZ = {$4$@@$4$
    $4$%%$4$property1 : value1,$4$@@$4$
    $4$%%$4$property2 : {$4$@@$4$
    $4$%%$4$$4$%%$4$     nestedProp1 : valueX1,$4$@@$4$
    $4$%%$4$$4$%%$4$     nestedProp2 : { $4$@@$4$
    $4$%%$4$$4$%%$4$$4$%%$4$         nestedNestedProp1 : valueXX1,$4$@@$4$
    $4$%%$4$$4$%%$4$$4$%%$4$         nestedNestedProp2 : valueXX2$4$@@$4$
    $4$%%$4$$4$%%$4$                },$4$@@$4$
    $4$%%$4$$4$%%$4$     nestedProp3 : valueX3$4$@@$4$
    $4$%%$4$$4$%%$4$         },$4$@@$4$
    $4$%%$4$property3 : value3$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    objectZ is the name of the variable.$4$@@$4$
    Within objectZ, there is an object, which holds the properties 1, 2 & 3$4$@@$4$
    Properties 1 & 3 hold values 1 & 3, but property2 holds another object – A NESTED OBJECT (i.e. an object within an object) with values nestedProp1, 2 & 3$4$@@$4$
    Yet again, the 2nd property within this (first) nested object holds ANOTHER nested object, with two more properties and their respective values$4$@@$4$
    $4$@@$4$
$4$@@$4$
    Let’s see how does the accessing work:$4$@@$4$
    >>$4$@@$4$
    objectZ$4$@@$4$
    <<$4$@@$4$
    will correspond to the whole value – so the whole outer object:$4$@@$4$
    >>$4$@@$4$
    {$4$@@$4$
    $4$%%$4$property1 : value1,$4$@@$4$
    $4$%%$4$property2 : {$4$@@$4$
    $4$%%$4$$4$%%$4$     nestedProp1 : valueX1,$4$@@$4$
    $4$%%$4$$4$%%$4$     nestedProp2 : { $4$@@$4$
    $4$%%$4$$4$%%$4$$4$%%$4$         nestedNestedProp1 : valueXX1,$4$@@$4$
    $4$%%$4$$4$%%$4$$4$%%$4$         nestedNestedProp2 : valueXX2$4$@@$4$
    $4$%%$4$$4$%%$4$                },$4$@@$4$
    $4$%%$4$$4$%%$4$     nestedProp3 : valueX3$4$@@$4$
    $4$%%$4$$4$%%$4$         },$4$@@$4$
    $4$%%$4$property3 : value3$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    objectZ.property1;$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    will correspond to the value within property1 :$4$@@$4$
    >>$4$@@$4$
    value1$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    objectZ.property2$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    will give us the value of property2:$4$@@$4$
    >>$4$@@$4$
             {$4$@@$4$
                 $4$%%$4$     nestedProp1 : valueX1,$4$@@$4$
                 $4$%%$4$    nestedProp2 : { $4$@@$4$
                  $4$%%$4$ $4$%%$4$nestedNestedProp1 : valueXX1,$4$@@$4$
                  $4$%%$4$ $4$%%$4$nestedNestedProp2 : valueXX2$4$@@$4$
                $4$%%$4$    },$4$@@$4$
                 $4$%%$4$nestedProp3 : valueX3$4$@@$4$
             }$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    we can then access  properties within that nested object by:$4$@@$4$
    >>$4$@@$4$
    objectZ.property2.nestedProp1$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    which will give us the value of the last listed property:$4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    valueX1$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    therefore, by accessing nestedProp2:$4$@@$4$
    >>$4$@@$4$
    objectZ.property2.nestedProp2$4$@@$4$
    <<$4$@@$4$
    will correspond to :$4$@@$4$
    >>$4$@@$4$
                 { $4$@@$4$
                              $4$%%$4$             nestedNestedProp1 : valueXX1,$4$@@$4$
                              $4$%%$4$             nestedNestedProp2 : valueXX2$4$@@$4$
                    },$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    and lastly, to get the values from the most inner nested object:$4$@@$4$
    >>$4$@@$4$
    objectZ.property2.nestedProp2.nestedNestedProp1$4$@@$4$
    <<$4$@@$4$
    and this will give us the given value:$4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    valueXX1$4$@@$4$
    <<$4$@@$4$
    and just as a last reminder, both . and bracket notation can be used interchangeably (don’t forget “”s for [] if accessing properties directly!):$4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    objectZ.property2[“nestedProp2”][“nestedNestedProp1”];$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    will give us the exact same result (or if in any other configuration)$4$@@$4$
    `)],

    forth: [insertNewLines(`Just like arrays, objects are also mutable, so the values of their keys can be changed:$4$@@$4$
    >>$4$@@$4$
    let object1 = {$4$@@$4$
    $4$%%$4$property1 : “funny guy”$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    object1.property1 = “not funny at all”;$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    In the example above, property1 within object1 will have its value changed as shown.`)],

    fifth: [insertNewLines(`In arrays, you would use the push( )/unshift( ) functions to add new elements, in objects however, you can add new properties simply in the same way as you’d change them, the syntax is the same. $4$@@$4$
    The idea is that if the given property does not exist within the object, it will simply be added, for example (following the object above):$4$@@$4$
    >>$4$@@$4$
    object1.property2 = “lol, I am a new value”;$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    object1 did not have a key called property2 before, and now it will, with the value as shown above.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    To delete properties, use *delete* keyword, followed by the path of the wanted property:$4$@@$4$
    >>$4$@@$4$
    delete object1.property1;$4$@@$4$
    <<$4$@@$4$
    now, property1 will be deleted from object1`)],

    sixth: [insertNewLines(`Lastly for objects (for now hehe), you can use the *.hasOwnProperty(propName)* function, to check if a property exists within an object. Yet again, just like with bracket notation, if you’re looking for a direct property name, you need to encase it with “”s, else, if you are looking for it through a variable, just but the variable’s name$4$@@$4$
$4$@@$4$
    The function will result true or false Boolean, for example (continuing off the object1 from above):$4$@@$4$
    >>$4$@@$4$
    object1.hasOwnProperty(“property1”);$4$@@$4$
    object1.hasOwnProperty(“property2”);$4$@@$4$
    <<$4$@@$4$
    the first line will return false (since we deleted property1) and the second line will return true$4$@@$4$
    $4$@@$4$
    The process is the same with nested objects, here’s some pseudocode:$4$@@$4$
    >$4$@@$4$
    object.nestedObj1.nestedObj2.hasOwnProperty(x);$4$@@$4$
    <`)],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default JavaScript_Objects;
  