import './LoadingCurtain.css'

function LoadingCurtainCollapse(props) {
  
  return (
    <div className='LoadingCurtainCollapse'
      >
        <h2>LOADING</h2>
      </div>
  );
}

export default LoadingCurtainCollapse;
