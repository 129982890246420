import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";
import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Loops() {
  const title = 'Loops'

  const paragraphs = {

    first: [insertNewLines(`WHILE LOOPS:$4$@@$4$
    While loops offer for a code to be executed, until a condition (or test) remains true. To illustrate this:$4$@@$4$
    >>$4$@@$4$
    let a = 0;$4$@@$4$
    $4$@@$4$
    while (a < 5) {$4$@@$4$
    $4$%%$4$a++;
    }$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    the test condition is that a is less than 5, until it is false, the code within the curly brackets will keep executing$4$@@$4$
    $4$@@$4$
    a++ means adding 1, to the value of a, this is known as incrementing, in the case, incrementing by 1, decreasing the value is called decrementing, to decrement by 1 -> variableName--; (same as incrementing but use minus sign)$4$@@$4$
    $4$@@$4$
    You can increment/decrement by more than 1 with the syntax:$4$@@$4$
    >>$4$@@$4$
    variableName+= increment value$4$@@$4$
    <<$4$@@$4$
    this means that the variable will equate to itself + the increment value, for example:$4$@@$4$
    >>$4$@@$4$
    let b = 0;$4$@@$4$
    let c = 0;$4$@@$4$
    $4$@@$4$
    b = b + 2;$4$@@$4$
    c += 2;$4$@@$4$
    <<$4$@@$4$
    in this example, b === c, as b equals b + 2 ( so 0 + 2) and c equals the exact same thing, just a short way of writing it – so c + 2 => 2 | 2 === 2$4$@@$4$
    $4$@@$4$
    Therefore, in the while loop formerly shown, a will be incremented by one in every iteration (repeat) of the loop – a will equal to 1, then 2, then 3…. Until it is incremented to 5, this will be when a = 4, and a is still less than 5, so the loop will be executed once more, making a == 5, now a < 5 is no longer true and the loop will stop iterating. So in this case, it will repeat 5 times before it stops.$4$@@$4$
    Something to look out for is that while loops can run indefinitely if an appropriate test is not given or the code inside it doesn’t let the test condition reach a false result.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    DO WHILE LOOPS, allow for a while loop to iterate at least once, REGARDLESS if the while test is false or not, this is done by the following syntax:$4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    do{$4$@@$4$
    $4$%%$4$…code…$4$@@$4$
    } while(…test…); $4$@@$4$
    <<$4$@@$4$
    NOTE THE SEMI-COLON AFTER THE WHILE TEST!$4$@@$4$
    $4$@@$4$
    With this syntax, the code within the do block will keep executing, until the test is false, HOWEVER – even if it is false to begin with, the code will still execute once.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    FOR LOOPS:$4$@@$4$
    For loops follow the following syntax: for(a;b;c){…code…}$4$@@$4$
    a is an initialized statement, usually, a variable initialization, the variable initialized here, will be used as an “incrementor value”, for the “test” of the loop$4$@@$4$
    b is the loops “test”$4$@@$4$
    c is a statement executed AFTER each loop iteration, PRIOR, to the check of the b test, usually, c is a statement incrementing/decrementing your incrementor value – a$4$@@$4$
    $4$@@$4$
    To show a functional example of a for loop:$4$@@$4$
    >>$4$@@$4$
    let stringVariable = “I want each character of this string separately”;$4$@@$4$
    let arr = [];$4$@@$4$
    $4$@@$4$
    for(let i = 0; i < stringVariable.length; i++){$4$@@$4$
    $4$%%$4$arr.push(string[i]);$4$@@$4$
    }$4$@@$4$
    <<
    $4$@@$4$
    in the example above, we declared a string and an empty array, $4$@@$4$
    a for loop is then created for(a;b;c){…code…}$4$@@$4$
    $4$@@$4$
    a => DECLARATION (if you want a new variable here, you’ll have to declare it, not just have i = 0, but you can also use a previously declared variable) of the variable i and initializing it to 0$4$@@$4$
    b => test condition, which states for the loop to run until:$4$@@$4$
    $4$%%$4$         i < stringVariable.length is no longer true$4$@@$4$
    c => every time the loop iterates – i is incremented by 1;$4$@@$4$
    {…code…} => the code within the loop states that every time the loop is executed, the character at stringVariable’s index i, is pushed into arr – the empty array$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    This creates a very clean loop, which iterates through each index of stringVariable.$4$@@$4$
    Since i is initially 0, the character at index 0 is first pushed in, i is then incremented and then the character at index 1 is pushed, until the test gives false.$4$@@$4$
    $4$@@$4$
    The test will give false when desired, this is because, the length of the string equals its actual length, but the last character is at an index length – 1, as index counting starts at 0, so if a string or an array has 10 elements, while its length is 10, the 10th element will be at index 9, and if the same loop is applied, when index = 10, where the test i < x.length will no longer apply, the loop won’t run, and so when the last digit was (previously) reached, it will be the last iteration and hence the loop will have iterated through each element in the array.$4$@@$4$
    $4$@@$4$
    In the coded loop given above, this will result in every single character from the string, being separately added to the array. $4$@@$4$
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Loops;
  