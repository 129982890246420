import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";
import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Boolean_Values_And_Comparison_Operators() {
  const title = 'Boolean Values and Comparison Operators'

  const paragraphs = {

    first: [insertNewLines(`Boolean values are values that can have only 2 values - true or false$4$@@$4$
    These values can act as an on/off switch, where the two states are MUTUALLY EXCLUSIVE$4$@@$4$
    $4$@@$4$
    The Boolean values true and false are written as they are - no “”s (that would create a string saying “true” and vice versa for false$4$@@$4$
    $4$@@$4$
    1 and 0 can also be used as Boolean values (as will be shown below)`)],

    second: [insertNewLines(`Comparison operators allow you to compare values:$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    1 - Equality operator:$4$@@$4$
    I - *==* :$4$@@$4$
    ->the equality operator checks for whether if two values are equal and returns a Boolean value (true or false)$4$@@$4$
    ->when using this operator alone, JavaScript compares the two data types by converting one data type to another, also known as TYPE COERCION and once it is done, the values can be compared as follows:$4$@@$4$
    $4$@@$4$
    1 == 1 -> returns true$4$@@$4$
    1 == ‘1’ -> returns true$4$@@$4$
    1 == “1” -> returns true$4$@@$4$
    1 == 2 -> returns false$4$@@$4$
    $4$@@$4$
    1 == true -> returns true$4$@@$4$
    0 == true -> returns false$4$@@$4$
    0 == false -> returns true$4$@@$4$
    1 == false -> returns false$4$@@$4$
    $4$@@$4$
    II - A STRICT equality operator - *===* checks for both the value AND the data type, hence, when using it, the type is not coerced, so values will be compared as follows:$4$@@$4$
    $4$@@$4$
    1 === 1 ->  returns true$4$@@$4$
    1 === '1' -> returns false$4$@@$4$
    1 === 2 -> returns false$4$@@$4$
    $4$@@$4$
    *data type of a variable can be checked by:$4$@@$4$
    typeof x - e.g. typeof '3' - returns string; typeof 3 - returns the STRING 'number'$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    2 - Inequality operator: $4$@@$4$
    I - *!=*:$4$@@$4$
    inequality operator checks for whether one value is NOT equal to another value, hence, it only returns true if the values are NOT equal to each other.$4$@@$4$
    like the example above, here, the data type will be converted before evaluation:$4$@@$4$
    $4$@@$4$
    1 != 2 -> returns true$4$@@$4$
    1 != ‘2’ -> returns true$4$@@$4$
    1 ! = 1 -> returns false $4$@@$4$
    1 != ‘1’ -> returns false$4$@@$4$
    1 != false -> returns true$4$@@$4$
    And like above, the inequality operator can also be strict :$4$@@$4$
    II - *!===*$4$@@$4$
    1 !=== ‘1’ -> returns true $4$@@$4$
    $4$@@$4$
    $4$@@$4$
    Lastly, the typical mathematical comparison signs can be used as comparative operators…if the comparison is correct, true will be returned and if not – false will be returned (all of these are non-strict):$4$@@$4$
    $4$@@$4$
    greater than - *>*$4$@@$4$
     less than - *<*$4$@@$4$
     greater than or equal to - *>=*$4$@@$4$
    less than or equal to *<=*$4$@@$4$
    Last note, is to remind both that the equality operator IS NOT the regular *=*, a single equals sign is used to declare variables, NOT to compare and secondly, that the comparison operators literally return true or false, to illustrate this:$4$@@$4$
    >>$4$@@$4$
    let x = 1;$4$@@$4$
    let y = 2; $4$@@$4$
    let c = x == y;$4$@@$4$
    <<$4$@@$4$
    c will literally have a value of false$4$@@$4$
    `)],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Boolean_Values_And_Comparison_Operators;
  