import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Callback_Functions() {
  const title = 'Callback Functions'

  const paragraphs = {

    first: [insertNewLines(`This section introduces us to callbacks. Though callbacks have existed forever and are not something sole to ES6, they will help you understand ES6 features in further sections.$4$@@$4$
    A callback, is when a function is used as the ARGUMENT of another function.$4$@@$4$
    $4$@@$4$
    This can be used to call the CALLBACK, within the function in which it is used as a parameter.$4$@@$4$
    $4$@@$4$
    For example:$4$@@$4$
    >>$4$@@$4$
    let variableX = 1;$4$@@$4$
    $4$@@$4$
    function thisIsMyCallback( ) {$4$@@$4$ 
    $4$%%$4$variableX = 2;$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    function thisUsesMyCallback(myCallbackParameter) {$4$@@$4$
    $4$%%$4$myCallbackParameter( );$4$@@$4$
    }$4$@@$4$
    thisUsesMyCallback(thisIsMyCallback);$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    The code above illustrates the MOST BASIC level of callbacks, its usefulness is irrelevant, this is just to take you step by step of the whole concept.$4$@@$4$
    $4$@@$4$
    In the case above, we have a variable, variableX – which equals 1. The thisIsMyCallBack function, will reassign variableX to 2. $4$@@$4$
    The function thisUsesMyCallback, is a function, that is MEANT TO take ANOTHER FUNCTION as an argument – it can take any parameter, but it will probably give an error if you use something else, since as you can see, its code puts ( )s in front of its parameter, effectively executing it and if the parameter is not a function, it cannot be executed and will hence throw an error.$4$@@$4$
    And this brings us to the culprit, as you can see, the parameter itself is just the name of the variable, it is not yet an executed function, but the parameter is then executed, IN THE CODE SECTION using ( )s. All of the changes in the function above will all only happen locally of course, as the variables are within a function block.$4$@@$4$
    $4$@@$4$
    As mentioned, this is just a very simple example, not to mention that you could just call the thisIsMyCallback function to achieve the same thing, without complicating it.$4$@@$4$
    $4$@@$4$
    Before going on to show the actual usability of callbacks, let’s look at another, more complicated callback example:$4$@@$4$
    >>$4$@@$4$
    function thisWillCallMyCallback(myCallbackParameter) {$4$@@$4$
    $4$%%$4$let name = “Benjamin”;$4$@@$4$
    $4$%%$4$return myCallbackParameter(name);$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    function myCallback(firstName) {$4$@@$4$
    $4$%%$4$ return  “Hello ” + firstName;$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    let helloMessage = thisWillCallMyCallback(myCallback);$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    Here, we have the former function, with a callback parameter, declaring a name variable inside it, which equals “Benjamin”.$4$@@$4$
    $4$@@$4$
    Following it, there is our callback function, which has the parameter – firstName, this parameter, will allow it’s given argument, to be concatenated with the “Hello “ string and be stored into the helloMessage variable, thus giving helloMessage the value of “Hello firstName”(firstName being the parameter)$4$@@$4$
    $4$@@$4$
    This means, that when the thisWillCallMyCallback function is called, with the myCallback function for its argument, it will then call the myCallback with the argument of the name variable (which = “Benjamin”) and so, helloMessage will obtain a value of “Hello Benjamin”. 
   $4$@@$4$
   $4$@@$4$
    Though this is fixed, we can easily have a desired, instead of a fixed value for the name variable by: $4$@@$4$
    >>$4$@@$4$
    function thisWillCallMyCallback(myCallbackParameter, nameParameter) {$4$@@$4$
    $4$%%$4$return myCallbackParameter(nameParameter);$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    function myCallback(firstName) {$4$@@$4$
    $4$%%$4$ return “Hello ” + firstName;$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    let helloMessage = thisWillCallMyCallback(myCallback, “Bubu”);$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    Now, we simply added a 2nd parameter to thisWillCallMyCallback and so when called, the myCallbackParameter function will be called with that 2nd parameter (nameParameter in this case).$4$@@$4$
    $4$@@$4$
    Even in this example, one can wonder, why not just call the myCallback with the desired name and yet again, this example was just to show the basic concept of callback and its syntax. $4$@@$4$
    $4$@@$4$
    The actual idea of callbacks then, is to allow for asynchronous programming, that is to say, programming that does not go in straight order.$4$@@$4$
    As we know, code in java script is red and executed from top down, so if you declare a variable and then reassign it, the final value will be that of its reassignment.$4$@@$4$
    $4$@@$4$
    Callback functions allow for ASYNCHRONOUS event structure. This event structure can be invoked in two ways – time delay, or by a value reassignment through an action, by (for example) the user, or by both.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    Let’s see an example of such functionality:$4$@@$4$
    >>$4$@@$4$
    let redButton = false;$4$@@$4$
    $4$@@$4$
    function redButtonPressed(result) { $4$@@$4$  
    $4$%%$4$     return ${'`${'} result }, it’s all gone!${'`'}$4$@@$4$
    }$4$@@$4$
$4$%%$4$
    function redButtonNotPressed(result) {$4$@@$4$
    $4$%%$4$     return ${'`'}Nothing new to report, ${'${'} result }${'`'}$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    function worldStatus(trueCallback, falseCallback) {$4$@@$4$
    $4$@@$4$
    $4$%%$4$     if (redButton == true) {$4$@@$4$
    $4$%%$4$$4$%%$4$         return trueCallback(“CABOOOOOOOOM!”)$4$@@$4$
    $4$%%$4$     } else { $4$@@$4$
    $4$%%$4$$4$%%$4$         return falseCallback(“still chillin’ in capitalism, at least for now.”)$4$@@$4$
         }$4$@@$4$
    }$4$@@$4$
$4$%%$4$
    worldStatus( redButtonPressed, redButtonNotPressed );$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    In the example above, we have 2 callbacks, one for when the red button is pressed and one for when it is not, each returning their respective results.$4$@@$4$
    $4$@@$4$
    In the case above, the final worldStatus call will return the string “Nothing new to report, still chillin’ in capitalism, at least for now” – as the redButton variable has a value of false, when the redbutton == true (which can simply be written as  *if (redButton)*, but for clarity’s sake, == true is included), the redButtonPressed function will be called, with the parameter of “CABOOOOOOOOM!” – which will then feed into the function and return “CABOOOOOOOOM!, it’s all gone!”$4$@@$4$
    $4$@@$4$
    Now do keep in mind, that due to our current limited knowledge, the value of the redButton variable here is fixed, but you have to take it with the idea that in real practice, the redButton variable will be a variable that can be changed depending on the decision of the user.$4$@@$4$
    $4$@@$4$
    There is a further step however, that can be used to make the code more concise/efficient:$4$@@$4$
    >>$4$@@$4$
    let redButton = false;$4$@@$4$
    $4$@@$4$
    function worldStatus(trueCallback, falseCallback) {$4$@@$4$
    $4$@@$4$
    $4$%%$4$$4$%%$4$     if (redButton == true) {$4$@@$4$
    $4$%%$4$$4$%%$4$         return trueCallback(“CABOOOOOOOOM!”)$4$@@$4$
    $4$%%$4$     } else { $4$@@$4$
    $4$%%$4$$4$%%$4$         return falseCallback(“still chillin’ in capitalism, at least for now.”)$4$@@$4$
     $4$%%$4$    }$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    worldStatus( function (result) {  $4$@@$4$ 
    $4$%%$4$     return ${'`${'} result }, it’s all gone!${'`'}$4$@@$4$
    }, function (result) {$4$@@$4$
    $4$%%$4$     return ${'`'}Nothing new to report, ${'${'} result }${'`'}$4$@@$4$
    });$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    NOW, we have removed the redButtonPressed/notPressed functions and we have instead, declared two anonymous functions, AS ARGUMENTS, straight into the worldStatus function, this code will act exactly the same as its previous counterpart, but making it slightly more efficient.$4$@@$4$
    $4$@@$4$
    The point here is not only to show the slight increase in efficiency, but also that you can feed functions straight into the parameter, BUT ALSO, even more importantly, to avoid one very significant confusion! As you can see, when the function is directly declared, it NEEDS ( )s (potentially parameters inside it, as we need one in our case), which IS NOT the case for when we have a pre-declared function. This is very important!!!$4$@@$4$
    $4$@@$4$
    The confusion comes from interpreting both the same way, when they are not the same thing!!! When we have a DECLARED function, we have a variable, which if we use with ( )s, we will CALL it, and so when setting it as an argument, and place ( )s at the name of the named function, we will end up calling it. Remember, when we make a variable = JUST function’s name, the variable just equals the function – so the code inside the function, NOT ITS RETURNED VALUE. But when we make a variable = function( ) – with the brackets, the variable will equal the OUTPUT of the function, as it is now called.$4$@@$4$
    $4$@@$4$
    However, when we have freshly declared a function, we have only done that – declared it, it is not called, so when a freshly declared function has ()s – it is still not called, it is just declared, but when a function name of an already declared function is given, WITH BRACKETS, you will CALL THE FUNCTION.
    `)],

    second: [insertNewLines(`So far, we have used very clear syntax to allow comprehension of the callback concept. It is important however, to see more concise syntax of the same code, in order to be able to read code when others write it, if they for example prefer that alternate syntax, let’s firstly see arrow use syntax:$4$@@$4$
    >>$4$@@$4$
    worldStatus( $4$@@$4$
    $4$%%$4$    result =>${'`${'} result }, it’s all gone!${'`'}$4$@@$4$
    , $4$@@$4$
    $4$%%$4$    result => ${'`'}Nothing new to report, ${'${'} result }${'`'}$4$@@$4$
    )$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    The code above, will call the worldStatus function, in just the same way as we did before – if you remember, when using arrow syntax, we don’t need to include the function keyword, furthermore, if we are using a single parameter, it doesn’t need to be encased in ( )s ( ( )s are still needed if there are no parameters or are more than 1)
    `)],

    third: [insertNewLines(`Going onto even more complex concepts, what if, we want multiple events to occur, one after another, where each further event is dependent in the previous (excluding the first event)$4$@@$4$
        $4$@@$4$
    What if, we have a number - and we want to then add 1 onto it, following that, we’d want to add another 1 onto it, following that, add another 1, and so on.$4$@@$4$
    $4$@@$4$
    As you can see – each of these events occurred because of the event before them occurred, this, can also be represented by callbacks, where when one event occurs, then so would the next, and the next and so on, WHILE STILL, all are dependent on the occurrence of the first.$4$@@$4$
    $4$@@$4$
    Let’s have a look:$4$@@$4$
    >>$4$@@$4$
    function ourCallback( ) {$4$@@$4$
    $4$%%$4$     return  1;$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    Firstly, we have created a function, that simply returns 1$4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    function ff (callbackFunction) {$4$@@$4$
    $4$%%$4$     return callbackFunction( )$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    Secondly, we have created a function, which can take our callback function as an argument, if we then call our ff function and store it in a variable:$4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    let variable1 = ff(ourCallback)$4$@@$4$
    <<$4$@@$4$
    now, variable 1 will = 1, this does not represent what we wanted to make however, it simply returns a fixed number 1, so we will need to modify our functions $4$@@$4$
    $4$@@$4$
    $4$@@$4$
    So far we have:$4$@@$4$
    >>$4$@@$4$
    function ourCallback( ) {$4$@@$4$
    $4$%%$4$     return  1;$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    function ff (callbackFunction) {$4$@@$4$
    $4$%%$4$     return callbackFunction( )$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    let variable1 = ff(ourCallback)$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    And we will change it to:$4$@@$4$
    >>$4$@@$4$
    function ourCallback(callback) {$4$@@$4$
    $4$%%$4$     return  callback + 1;$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    function ff (callbackFunction) {$4$@@$4$
    $4$%%$4$     return callbackFunction( )$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    let variable1 = ff(ourCallback)$4$@@$4$
    <<$4$@@$4$
    the problem is that now, when we call ff, initially, ourCallback returns the parameter + 1, however, we have no parameter! So variable 1 will equate to NaN, this raises the question, why don’t we just give a parameter to ourCallback, when calling ff, at the declaration of variable1 – this is because we can’t – ff takes a function parameter, but now we are executing ourCallback directly into ff and so we will get an error.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    To fix this, we can declare a function, which executes ourCallback, with a parameter, as so:$4$@@$4$
    >>$4$@@$4$
    let variable1 = ff(  ( ) => ourCallback(0)  )$4$@@$4$
    <<$4$@@$4$
    this would be the same as:$4$@@$4$
    >>$4$@@$4$
    let variable1 = ff(  function( ) {return ourCallback(0)  }  )$4$@@$4$
    <<$4$@@$4$
    This will work for us, we are now declaring the function as the ff argument, rather than giving a function that has already been declared.$4$@@$4$
    $4$@@$4$
    So the result of the above, will mean that variable1 = 1, as ourCallback has been called and executed again,$4$@@$4$
    but we want further 1s to be added to our original argument of 0.$4$@@$4$
    To do this:$4$@@$4$
    >>$4$@@$4$
    let variable1 = ff(   ( ) =>  ourCallback( ourCallback(0)  )     )$4$@@$4$
    <<$4$@@$4$
    which would be the same as: $4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    let variable1 = ff(   function( ) { return ourCallback( ourCallback(0) )  }   )$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    wtf did we just do? What we did, is that we called callback, with the parameter of ourCallback(0) itself. Now:$4$@@$4$
    $4$@@$4$
    ourCallback(0) executes – returning 1$4$@@$4$
    1 then feeds as a parameter of ourCallback( ourCallback(0) ) and that makes$4$@@$4$
    1 + 1 = 2, so now variable1 = 2$4$@@$4$
    $4$@@$4$
    And since we cannot have a parameter for ourCallback, we need to indent it into a function that is declared$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    If we then continue:$4$@@$4$
    >>$4$@@$4$
    let variable1 = ff(   ( ) => ourCallback( ourCallback( ourCallback(0) )  )     )$4$@@$4$
    <<$4$@@$4$
    now, variable1 = 3, so essentially, you are calling a callback within a callback$4$@@$4$
    `)],

    forth: [insertNewLines(`On a last note, just to show this as a visual:$4$@@$4$`)],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          <h5 style={{textAlign:'center'}}>{'(images bellow scrollable on phone)'}</h5>
          <div className='callback-image-container1'>
            <div className='callback-image1' ></div>
          </div>
          <div className='callback-image-container2'>
            <div className='callback-image2' ></div>
          </div>

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Callback_Functions;
  