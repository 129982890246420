import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Mixin() {
  const title = 'Mixin'

  const paragraphs = {

    first: [insertNewLines(`We learnt that behaviour can be shared through inheritance. That said, in some cases, inheritance is not the best solution. $4$@@$4$
$4$@@$4$
    Inheritance does not work too well for unrelated objects. In the previous example, we created an Actions constructor and gave it a flight method, which was then inherited by the Bird( ) constructor.$4$@@$4$
    What if we want to create a Cat( ) constructor and add an eat method which logs (“NOM NOM”) to both?$4$@@$4$
    $4$@@$4$
    If we add that method to Actions, and make them both inherit Actions, they will also both inherit flight – and cats don’t fly.$4$@@$4$
    $4$@@$4$
    Therefore, for such unrelated objects, we can create a mixin function, that adds a property/method, directly to the desired object:$4$@@$4$
    >>$4$@@$4$
    let eatMixin = function (desiredObjectName) { $4$@@$4$
    $4$%%$4$desiredObjectName.eat = function( ) { console.log(“NOM NOM”);};$4$@@$4$
    };$4$@@$4$
    <<$4$@@$4$
    if we now create an object and pass it onto the eatMixin, the object will obtain the eat method:$4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    eatMixin(sparrow); /*sparrow (from code from before) */ passed into eatMixin$4$@@$4$
    $4$@@$4$
    let tiger = {            /* create our other object (just as an example) */$4$@@$4$
    $4$%%$4$legs: 4,$4$@@$4$
    $4$%%$4$colours: “orange-white-black stripes”$4$@@$4$
    $4$%%$4$relativeSize: “largest of the cats”$4$@@$4$
    }$4$@@$4$
    eatMixin(tiger);     /* tiger passed into eatMixin */$4$@@$4$
    $4$@@$4$
    sparrow.eat( );$4$@@$4$
    tiger.eat( );$4$@@$4$
    <<$4$@@$4$
    the console will log “NOM NOM” twice
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Mixin;
  