import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";
import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Functions() {
  const title = 'Functions'

  const paragraphs = {

    first: [insertNewLines(`The syntax for declaring a function:$4$@@$4$
    >>$4$@@$4$
    function functionName( ) { …code…}$4$@@$4$
    <<$4$@@$4$
    ->within the curly brackets, is written the code, which would be executed when the function is called$4$@@$4$
    -> within the ( )s, the parameters are given, a function does not necessarily need parameters within it, but if there are any, they are written within the  “normal”brackets$4$@@$4$
    $4$@@$4$
    some examples of functions:$4$@@$4$
    $4$@@$4$
    EXAMPLE1:$4$@@$4$
    >>$4$@@$4$
    function giveStringX( ) {$4$@@$4$
    $4$%%$4$return “X”;$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    let string = giveStringX( );$4$@@$4$
    <<$4$@@$4$
    the return keyword, states to literally return a value back, to/ for where the function is called, in this case, the returnStringX() function, returns the string “X”, so when the function is called to be assigned to the string variable, string equals “X”. NOTE - when CALLING the function, the () brackets are still needed for the function to be executed, if they are NOT included, the program will read as if you want to assign the variable to equal the function itself, so string will equal the whole of the function returnStringX (effectively becoming a function itself)$4$@@$4$
    $4$@@$4$
    EXAMPLE2:$4$@@$4$
    >>$4$@@$4$
    function getParameterPlusOne(p1) {$4$@@$4$
    $4$%%$4$return p1 + 1;$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    let variableY = getParameterPlusOne(5);$4$@@$4$
    <<$4$@@$4$
    here, variableY will store the value of 6$4$@@$4$
    as you can see, getParameterPlusOne has one parameter - p1, the function is programmed to return the parameter + 1. $4$@@$4$
    $4$@@$4$
    NOTE - any operations AFTER the return keyword are executed BEFORE the value is actually returned, this is very useful, because we can instantly return the equation which we want, rather than having to create an equation, store it into a variable and then return that variable.$4$@@$4$
    Furthermore - while p1 is a parameter and is used within the function, the value that is passed INTO the function is the argument - hence, here, 5 is the argument.$4$@@$4$
    $4$@@$4$
    There can also be multiple parameters within a function - it is only important when passing in the arguments, you pass them in the correct order$4$@@$4$
    $4$@@$4$
    EXAMPLE3:$4$@@$4$
    >>$4$@@$4$
    function functionWithTwoParametersNoShee(greeting, name) {$4$@@$4$
    $4$%%$4$return greeting + “ “ + name;$4$@@$4$
    let z = functionZeroAndSquare(“Naruto”, “Hello”);$4$@@$4$
    <<$4$@@$4$
    variable z will equal “Naruto Hello”, as “Naruto” was passed in for the first parameter, and “Hello” as the second, hence, when the function returns the greeting parameter first, it returns “Naruto” then the “Hello” string, this is an example showing the importance of giving an input of arguments in the correct order.
    `)],

    second: [insertNewLines(`Something important to note, is that when a function meets a return keyword, it returns the given code and further execution stops. So far, we have been working only with very simple functions and this importance may not be fully seen, but to give a short demonstration of this:$4$@@$4$
    >>$4$@@$4$
    function noChangeAfterReturn(p1) {$4$@@$4$
    $4$%%$4$let variable1 = p1;$4$@@$4$
    $4$%%$4$return variable1 + 1;$4$@@$4$
    $4$@@$4$
    $4$%%$4$variable1 = p1 + 5;$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    in the function above, when the function meets the return statement, all code below will not be executed, so although variable1 is changed after the return statement, this will not have an effect and what will be returned is variable1 + 1, which equals p1 + 1  ( instead of the accumulated p1 + 5 + 1 )$4$@@$4$
    this functionality can be used to your advantage and will be shown later own$4$@@$4$
    $4$@@$4$
    if a function which does not contain a return statement is called, the value of “undefined” will be returned
    `)],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Functions;
  