import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";
import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function If_If_Else_If_Else_If_Statements() {
  const title = 'If/If-Else/If-Else if Statements'

  const paragraphs = {

    first: [insertNewLines(`if statements, allow code to "make decisions" - if statements, tells JavaScript to$4$@@$4$
    execute code (in the curly brackets), if given a “test” fulfills the if conditions,$4$@@$4$
    hence the conditions are BOOLEAN CONDITIONS (since the conditions can $4$@@$4$
    either be fulfilled or NOT fulfilled - thus being only true or false). In the case$4$@@$4$
    where the conditions are not fulfilled - the code won't run$4$@@$4$
    $4$@@$4$
    Let’s have a look at an if statement’s syntax:$4$@@$4$
    >>$4$@@$4$
    let a = 1;$4$@@$4$
    let b;$4$@@$4$
    if ( a == 1){$4$@@$4$
    $4$%%$4$b = 2;$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    the code follows the following structure:$4$@@$4$
    $4$@@$4$
    if(…test…) {…code…}$4$@@$4$
    $4$@@$4$
    in the given example, a is initialized with the value of the number 1. The if statement, puts forth a test – if the test is true – the code within the if BLOCK is executed, if not, it isn’t.$4$@@$4$
    $4$@@$4$
    In this case, a == 1 returns true, and so the code is executed, where the previously declared but not initialized variable b, will be given a value of 2.$4$@@$4$
    $4$@@$4$
    As a useful note, you can literally write (true) as the if statement – which will make the statement (always…) execute.$4$@@$4$
    `)],

    second: [insertNewLines(`After the initial if statement, an else statement can be attached, this will make is so that if the original if statement “test” is not fulfilled, the program will then execute the code within the else BLOCK:$4$@@$4$

    >>$4$@@$4$
    let x = 2;$4$@@$4$
    let b;$4$@@$4$
    $4$@@$4$
    if(x == 1){$4$@@$4$
    $4$%%$4$b = 2;$4$@@$4$
    }else {$4$@@$4$
    $4$%%$4$b = 3;$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    the snippet above will conclude with b having the value of 3,$4$@@$4$
    since the first if “test” is not fulfilled, the program then executes the code within the else BLOCK, hence, giving the value of b == 3
    `)],

    third: [insertNewLines(`Lastly, if – else if statements will work as a mix of the above two.$4$@@$4$
    If the first “test” is not fulfilled, the program will then go on to check another given test, at the following else if(){} – if not fulfilled, the program will go on to check for another else if (){} (if given…), lastly, an else statement can be given if none of the tests are fulfilled:$4$@@$4$
    >>$4$@@$4$
    let y = 3;$4$@@$4$
    let b;$4$@@$4$
    $4$@@$4$
    if(y == 1){$4$@@$4$
    $4$%%$4$b = 2;$4$@@$4$
    }else if(y == 2){$4$@@$4$
    $4$%%$4$b = 3;$4$@@$4$
    }else {$4$@@$4$
    $4$%%$4$b = 4;$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    the program above will conclude with b having a value of 4, as none of the tests are fulfilled`)],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default If_If_Else_If_Else_If_Statements;
  