import './LoadingCurtain.css'

function LoadingCurtainExpand(props) {
  
  return (
    <div className='LoadingCurtainExpand' style={{display: `${props.appear > 0 ? 'none' : 'unset' }`}}>
        <h2>LOADING</h2>    
    </div> 
  );
}

export default LoadingCurtainExpand;
