import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Promises() {
  const title = 'Promises'

  const paragraphs = {

    first: [insertNewLines(`Just like the getters and setters, promises are a syntax, that makes executing a particular task better, where we would say that the difference is somewhat bigger and indeed better, compared to the getters and setters. $4$@@$4$
    $4$@@$4$
    A promise in javaScript, acts just a literal one, you make a promise to do something and based on whether that promise is fulfilled or not, an outcome is generated.$4$@@$4$
    $4$@@$4$
    Here’s the basis for initiating a promise:$4$@@$4$
    >>$4$@@$4$
    const promiseVariable =  new Promise ( (resolve, reject) => { } ); $4$@@$4$
    <<$4$@@$4$
    as you can see, the promise takes two parameters – resolve and reject, each standing as parameters for a callback function, this will be seen further ahead.$4$@@$4$
    $4$@@$4$
    A promise has 3 states – fulfilled, rejected and pending.$4$@@$4$
    When the promise is not completed (neither fulfilled, nor rejected) – it is stuck in its pending state. $4$@@$4$
    The resolve and reject arguments are what will help complete the promise.$4$@@$4$
    The resolve argument is given for the case of success of your code logic and reject – for the case of failure in your code logic.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    >$4$@@$4$
    const promiseVariable =  new Promise ( (resolve, reject) => { $4$@@$4$
    $4$%%$4$if(condition) {$4$@@$4$
    $4$%%$4$$4$%%$4$     resolve( callback input for a success ); $4$@@$4$
    $4$%%$4$   } else { $4$@@$4$
    $4$%%$4$$4$%%$4$     reject( callback input for a failure );$4$@@$4$
    $4$%%$4$   }$4$@@$4$
    });$4$@@$4$
    <$4$@@$4$
    as you can see, our resolve and reject parameters accept callback functions, and though they don’t have ( )s at the parameter placeholder, they are then invoked, by ( )s, within the code block, together with the desired input.$4$@@$4$
    $4$@@$4$
    Promises can be used to direct the outcomes, of a server request, to show this:$4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    const makeServerRequest = new Promise ( (resolve, reject) => {$4$@@$4$
    $4$%%$4$   if(responseFromServer == true) {$4$@@$4$
    $4$%%$4$$4$%%$4$    resolve(“Data request successful”);$4$@@$4$
    $4$%%$4$   } else {$4$@@$4$
    $4$%%$4$$4$%%$4$     reject(“Data request failed”);$4$@@$4$
         }$4$@@$4$
    });$4$@@$4$
    <<$4$@@$4$
    $4$@@$4$
    Just remember, (responseFromServer == true) is the same as (responseFromServer).$4$@@$4$
    Effectively, if the data was received (in this imaginary scenario), the resolve function will be called, with the input of the string “Data request successful”, and vice-versa – “Data request failed”, if our request was not accepted for whatever reason.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    So how do we call a Promise then?$4$@@$4$
    When we did callbacks, we would just call the main function, which accepted the callback argument, with promises, we instead do this:$4$@@$4$
    $4$@@$4$
    >$4$@@$4$
     const promiseVariable =  new Promise ( (resolve, reject) => { $4$@@$4$
        $4$%%$4$   if(condition) {$4$@@$4$
             $4$%%$4$$4$%%$4$     resolve( callback input for a success ); $4$@@$4$
    $4$%%$4$   } else { $4$@@$4$
    $4$%%$4$$4$%%$4$     reject( callback input for a failure );$4$@@$4$
     $4$%%$4$  }$4$@@$4$
    });$4$@@$4$
    promiseVariable.then(result => { $4$@@$4$
    $4$%%$4$code using the result (this would be the result of the resolve – i.e. the resolve’s argument) } );$4$@@$4$
    $4$@@$4$
    promiseVariabe.catch(error => { $4$@@$4$
    $4$%%$4$code using the error (this would be the result of the reject – i.e. the reject’s argument) } );$4$@@$4$
    <$4$@@$4$
    Hence, we would be using the promise.then for a success and promise.catch for a failure.$4$@@$4$
    This can also be written as so:$4$@@$4$
    >$4$@@$4$
    promiseVariable.then(result => { $4$@@$4$
    $4$%%$4$    code using the result (this would be the result of the resolve – i.e. the resolve’s argument) } )$4$%%$4$
    .catch(error => { $4$@@$4$
    $4$%%$4$code using the error (this would be the result of the reject – i.e. the reject’s argument) } );$4$@@$4$
    <$4$@@$4$
    Lastly, the actual bit that promises makes much easier, is callbacks within callbacks, previously you would have to indent them within one another, now:$4$@@$4$
    >$4$@@$4$
    promiseVariable.then(result => { $4$@@$4$
    $4$%%$4$code using the result (this would be the result of the resolve – i.e. the resolve’s argument) } )$4$@@$4$
    .then(result => { $4$@@$4$
    $4$%%$4$code using the result (this would be the result of the resolve – i.e. the resolve’s argument) } )$4$@@$4$
    <$4$@@$4$
    i.e. – you just add another then, and works just the same with catch.`)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Promises;
  