import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Adding_Multiple_Prototype_Properties_At_Once() {
  const title = 'Adding Multiple Prototype Properties At Once'

  const paragraphs = {

    first: [insertNewLines(`Originally, we showed the addition of prototype properties using:$4$@@$4$
    >$4$@@$4$
    objectName.prototype.newPrototypePropName = x;$4$@@$4$
    <$4$@@$4$
    This way, however, you are only adding a single property at a time, which means that you will have to repeat your code again and again, if you want to add multiple properties, which can both result to errors, as well as making the code harder to edit in the future, when you need to edit our every single repetitive line.$4$@@$4$
    You can therefore, add multiple properties at once, by:$4$@@$4$
    >$4$@@$4$
    objectName.prototype = {$4$@@$4$
    $4$%%$4$protoProp1 : val1,$4$@@$4$
    $4$%%$4$protoProp2 : val2,$4$@@$4$
    $4$%%$4$protoProp3 : val3$4$@@$4$
    …$4$@@$4$
    }$4$@@$4$
    <$4$@@$4$
    HOWEVER, IT IS IMPORTANT TO UNDERSTAND, that using this method, you are basically re-assigning the whole prototype object, therefore, deleting any properties previously in it (as you are overwriting the whole former object).$4$@@$4$
    $4$@@$4$
    THAT SAID, NOW, IF YOU:$4$@@$4$
    Create an instance$4$@@$4$
    Add an INDIVIDUAL property to the (new) constructor prototype$4$@@$4$
    Have an initial constructor$4$@@$4$
    Create another NEW PROTOTYPE PROPERTY$4$@@$4$
    The OLD prototype object, which has been in any instances up till now, WILL REMAIN INTO THOSE INSTANCES and if you try to add any new properties, individually, those properties will go to the NEWLY ASSIGNED PROTOTYPE PROPERTY – THE INSTANCES WITH THE OLD PROTOTYPE WILL NOT INHERIT ANY OF THESE INDIVIDUALLY ADDED PROPERTIES$4$@@$4$
    $4$@@$4$
    Therefore, the NEW PROTOTYPE (the one reassigned as shown above) will ONLY BE INHERITED BY THE INSTANCES OF THE CONSTRUCTOR, CREATED AFTER THE CONSTRUCTOR’S PROTOTYPE HAS BEEN CHANGED$4$@@$4$
    To show this:$4$@@$4$
    >>$4$@@$4$
    function Constructor1( ) { }   /* create a constructor */ (giving empty object)$4$@@$4$
    $4$@@$4$
$4$%%$4$let instance1 = new Constructor1( )  /* create the 1st instance */$4$@@$4$
$4$%%$4$Constructor1.prototype.prototypeProp1 = “lol”;$4$@@$4$
    $4$@@$4$
$4$%%$4$console.log(instance1.prototypeProp1);  /* console will display “lol” */$4$@@$4$
    $4$@@$4$
$4$%%$4$Constructor1.prototype = {$4$@@$4$
    $4$%%$4$prototypeProp1 : “haha”     /* CHANGING USING PROTOTYPE REASSIGN */$4$@@$4$
    }                                        /* THE PROTOTYPE IS NOW A NEW OBJECT */$4$@@$4$
    
    let instance2 = new Constructor( ) /* creating 2nd instance AFTER REASSIGN */$4$@@$4$
    console.log(instance2.prototypeProp1); /* console displays “haha” */$4$@@$4$
    console.log(instance1.prototypeProp1);  /* console STILL displays “lol” */$4$@@$4$
    $4$@@$4$
    Constructor1.prototype.prototypeProp2 = “boo”; /*adds a 2nd property to the NEW PROTOTYPE PROPERTY in constructor */$4$@@$4$
                                               $4$@@$4$                                          
    console.log(instance2.prototypeProp2); /* console displays “boo” */$4$@@$4$
    console.log(instance1.prototypeProp2);  /* console displays undefined */$4$@@$4$
    <<     $4$@@$4$             
    Therefore, the total console display will be: $4$@@$4$
    lol             →   From instance1.prototypeProp1$4$@@$4$
    haha          →   From instance2.prototypeProp1$4$@@$4$
    lol             →   From instance1.prototypeProp1$4$@@$4$
    boo           →   From instance2.prototypeProp2$4$@@$4$
    undefined   →   From instance1.prototypeProp2$4$@@$4$
    $4$@@$4$
    As you can see, instance1, retained its original prototype property, which was inherited from Constructor1 initially.$4$@@$4$
    $4$@@$4$
    On the other hand, instance2 inherited the NEW PROTOTYPE property – which was completely different.$4$@@$4$
    $4$@@$4$
    Not only were the same .prototypeProp1 giving different values, as they were from different objects, 
    but also, when the new prototypeProp2 was added, it was only inherited in the instance2, created AFTER the Constructor1 prototype reassignment.$4$@@$4$
    $4$@@$4$
    Since it was not inherited by instance1, which only held THE OLD prototype property – when we tried to access instance1.prototypeProp2 – undefined was returned.$4$@@$4$
    $4$@@$4$
    This is because the new prototypeProp2 was only added to the NEW PROTOTYPE object and NOT THE OLD ONE$4$@@$4$
    $4$@@$4$
    Furthermore – as a side note, notice that instance1 was created BEFORE, the prototypeProp1 (== “lol”) got added INDIVIDUALLY, this shows that properties added individually are inherited by instances even BEFORE the individual addition.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    A conclusion to take from this, is that, if for example, you want to avoid the whole old – new problem, you can just do this reassignment at the VERY BEGINNING, BEFORE creating any instances, this way, all of the instances will be new and inherit the new prototype property.$4$@@$4$
    $4$@@$4$
$4$@@$4$
    HOWEVER, AS A LAST NOTICE, when you set the prototype property manually like so (reassigning it), the constructor property will be MODIFIED/ERASED, this therefore means, that if you use the constructor property to check which constructor created a given instance, you will get false results.$4$@@$4$
    For example (following the example above):$4$@@$4$
    >>$4$@@$4$
    console.log(instance2.constructor === Constructor1);$4$@@$4$
    console.log(instance2.constructor === Object);  // more on this later$4$@@$4$
    $4$@@$4$
    console.log(instance2 instanceof Constructor1);$4$@@$4$
    <<$4$@@$4$
    The console will display false true true$4$@@$4$
    $4$@@$4$
    As the constructor property has been modified/erased, the first console.log will return false, as the value of the instance2, no longer equals the name of its constructor, instead, it now === Object – more on this in the next subsection.$4$@@$4$
    If you use the *instanceof* operator, true is returned.$4$@@$4$
    $4$@@$4$
    An easy fix, however, is to simply re-add the constructor property, within the new prototype, every time you reassign the prototype property$4$@@$4$
    Here is a better written Constructor1.prototype from the main example:$4$@@$4$
    >>$4$@@$4$
    Constructor1.prototype {$4$@@$4$
    $4$%%$4$constructor : Constructor1,$4$@@$4$
    $4$@@$4$
    $4$%%$4$prototypeProp1 : “haha”$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    now, the above 3 true/false results will give: true false true (if we imagine that we edited our former code, otherwise it is like yet another reassignment...)$4$@@$4$
    $4$@@$4$
    Notice that the value of the constructor is the name just by itself – no “”/’’s$4$@@$4$
    $4$@@$4$
    Hence, every time you create a new prototype by reassignment…assign a constructor as well (which equals the constructor’s name), in order not to lose the former constructor property.
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Adding_Multiple_Prototype_Properties_At_Once;
  