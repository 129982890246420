import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Pass_By_Value_VS_Pass_By_Reference() {
  const title = 'Pass By Value VS Pass By Reference'

  const paragraphs = {

    first: [insertNewLines(`In JavaScript, all primitive values (numbers, Booleans, strings, undefined, null), are all passed BY VALUE.$4$@@$4$
$4$@@$4$
    What that means, is that, when you assign a PRIMITIVE value to a variable, the value assigned (right hand side), is copied into the variable (left hand side), this way, the variable equals an actual value (which is the copy of the value passed in)$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    Non-primitive values (such as arrays or objects), however, are passed in BY REFERENCE.$4$@@$4$
    $4$@@$4$
    What that means, is that, when you assign a NON-PRIMITIVE value to a variable, you are actually ASSIGNING AN ADDRESS, WHICH ITSELF HAS A VALUE to the variable – and so the variable equals the given address and it’s value, rather than just a raw value.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    So what are the practical consequences of either passes?$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    PASS BY VALUE:$4$@@$4$
    Let’s look at some code:$4$@@$4$
    >>$4$@@$4$
    let a = 3;$4$@@$4$
    let b = “lol”;$4$@@$4$
    let c = a;$4$@@$4$
    <<$4$@@$4$
    here, a and b both have primitive values assigned to them. This means that each variable in itself, has now copied the assigned value given to it, in itself. $4$@@$4$
    $4$@@$4$
    When we assign – let c = a, since a has a is a number, with value of 3 in itself, c will also equal 3 and a will be passed in by VALUE (because 3 = number). $4$@@$4$
    $4$@@$4$
    IT IS IMPORTANT TO UNDERSTAND THEREFORE, that c and a are both SEPARATE VARIABLES, both, with a value of 3, they in themselves equal 3.$4$@@$4$
    $4$@@$4$
    If we therefore make the following changes:$4$@@$4$
    >>$4$@@$4$
    let a = 3;$4$@@$4$
    let b = “lol”;$4$@@$4$
    let c = a;$4$@@$4$
    c = c +1;$4$@@$4$
    $4$@@$4$
    console.log(a, c);$4$@@$4$
    <<$4$@@$4$
    console will display 3, 4$4$@@$4$
    $4$@@$4$
    This is because a and c are separate variables and changing one, has no effect on the other, they are literally called from different places from your computer’s memory.$4$@@$4$
    $4$@@$4$
    THEREFORE, BOTH a AND c ARE POINTING TO THEIR OWN VALUES$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    PASS BY REFERENCE:$4$@@$4$
    If we look at variables, which have NON-PRIMITIVE VALUES assigned to them:$4$@@$4$
    >>$4$@@$4$
    let d = [1,2,3];$4$@@$4$
    let e = d;$4$@@$4$
    e.push(4);$4$@@$4$
    $4$@@$4$
    console.log(d,e)$4$@@$4$
    <<$4$@@$4$
    console will display [1,2,3,4], [1,2,3,4]$4$@@$4$
    $4$@@$4$
    d = an array, which is NON-PRIMITIVE, this means, that you are not passing/storing an actual value into the variable. Instead, you are passing a memory address, for example, the memory address of 0x01. THIS ADDRESS THEN HAS A VALUE, OF THE ARRAY – [1,2,3]$4$@@$4$
    $4$@@$4$
    Therefore, when you assign let e = d, you are just giving the value of e, the same value of d, which is the memory address 0x01, INSTEAD OF AN ACTUAL VALUE. $4$@@$4$
    $4$@@$4$
    When you then change the value of e, by pushing 4 into it, you are CHANGING THE VALUE OF THE MEMORY ADDRESS GIVEN TO IT.$4$@@$4$
    $4$@@$4$
    Therefore, now, since the value of the memory address 0x01 is changed, this means that all variables which have the value of that memory address, will give that changed value (because they are giving the value of the address itself) – [1,2,3,4]$4$@@$4$
    $4$@@$4$
    If we however, create a new variable, by giving it a new literal array:$4$@@$4$
    >>$4$@@$4$
    let d = [1,2,3];$4$@@$4$
    let e = d;$4$@@$4$
    e.push(4);$4$@@$4$
    let f = [1,2,3,4]$4$@@$4$
    $4$@@$4$
    console.log( e == d, f == d)$4$@@$4$
    <<$4$@@$4$
    console will display true, false$4$@@$4$
    $4$@@$4$
    This is because, we have now given a completely new memory address to f – 0x02 – which has a value of [1,2,3,4]$4$@@$4$
    $4$@@$4$
    So e == d because they both have the same value of the same memory address, but f != d, or e, because f has a value of a different memory address, even as both addresses have the same values, those values are SEPARATE values of one another, just like before with a and c.$4$@@$4$
    $4$@@$4$
    If we modify d/e, f will remain the same and vice-versa.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    Lastly, if you remember, in the beginning of JavaScript and Programming Foundations, we went over var, let and const.$4$@@$4$
    $4$@@$4$
    const variables, while unable to be reassigned, were still capable of being MUTATED. This is because, when you are mutating an (for example) array variable, you are not changing its value, which is the ADDRESS to which the variable is pointing to, but you are changing the VALUE OF THE ADDRESS ITSELF.$4$@@$4$
    $4$@@$4$
    For example:$4$@@$4$
    >>$4$@@$4$
    const g = [1,2,3,4];$4$@@$4$
    $4$@@$4$
    g = [1,2,3,4,5];$4$@@$4$
    <<$4$@@$4$
    This would give an error, as g equals the address 0x03, and you are trying to give it a value of a new address - 0x04 (these addresses are hypothetical…).$4$@@$4$
    $4$@@$4$
    On the other hand:$4$@@$4$
    >>$4$@@$4$
    const g = [1,2,3,4]$4$@@$4$
    g.push(5);$4$@@$4$
    <<$4$@@$4$
    This will “work” and the g will give the array [1,2,3,4,5], HOWEVER, its address, 0x03 will remain UNCHANGED, even as the value of the address itself, is changed from [1,2,3,4], to [1,2,3,4,5].$4$@@$4$
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Pass_By_Value_VS_Pass_By_Reference;
  