import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Useful_Commands_For_Bug_Checking_Debugging() {
  const title = 'Useful Commands For Bug Checking Debugging'

  const paragraphs = {

    first: [insertNewLines(`
    Console.log( ) – this function makes the console display the input given in it, this can be anything, from variables, literals, functions, etc. $4$@@$4$
$4$@@$4$
    To show an example of this:$4$@@$4$
    >>$4$@@$4$
    let variableX = “lol”$4$@@$4$
    let variableY = { $4$@@$4$
     $4$%%$4$ prop1 : “haha”,$4$@@$4$
    $4$%%$4$prop2: “ff”$4$@@$4$
    };$4$@@$4$
    console.log(variableX, variableY, “wtf”, 4);$4$@@$4$
    <<$4$@@$4$
    Here, the console will log the values of variableX and Y ( “lol” and 
    { prop1 : “haha”, prop2: “ff”} ) then print the string “wtf” and 4, this also shows that you can use the console.log( ) function to display multiple values at once.$4$@@$4$
    $4$@@$4$
    Furthermore, you can use console.clear; to clear what the console has displayed so far.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    typeof x – this will return the type of x, where x can be both a literal and a function.$4$@@$4$
    $4$@@$4$
    JavaScript will recognise 7 primitive (IMMUTABLE) data types:$4$@@$4$
    Boolean, Null, Undefined, Number, String, Symbol and BigInt,$4$@@$4$
    as well as one MUTABLE item – Object, where both object and arrays are recognised as an Object.$4$@@$4$
    $4$@@$4$
    The typeof can be used in our regular code – for example as an *if* condition, where the code runs only of a given variable has a certain data type, or in conjunction with the console, where the typeof will return the data type and display it in the console, for example:$4$@@$4$
    >>$4$@@$4$
    let variableX = “lol”;$4$@@$4$
    console.log(typeof variableX);$4$@@$4$
    console.log(typeof [ ]);$4$@@$4$
    console.log(typeof “haha”);$4$@@$4$
    <<$4$@@$4$
    will display String, Object and String at the console.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    Lastly, code can be commented, that is to say, that you can write text within the code section itself and have that text ignored by the program.$4$@@$4$
    You can use this text not as a part of the program, but to explain what does you program do, within the code section itself.$4$@@$4$
    $4$@@$4$
    // - creates a comment for the given line ONLY $4$@@$4$
    /* & */ - here, /* will open a comment section, which will end when */ is reached$4$@@$4$
    For example:$4$@@$4$
    >> $4$@@$4$
    let varX = “lol” //declares and initiates varX to string “lol”$4$@@$4$
    let varY = “this is so funny hahahah” + varX  /* declares and initiates varY to varX + the concatenation of the string “this is so funny hahahah” */$4$@@$4$
    <<
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Useful_Commands_For_Bug_Checking_Debugging;
  