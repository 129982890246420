import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Common_Bugs() {
  const title = 'Common Bugs'

  const paragraphs = {

    first: [insertNewLines(`
    I - Spelling mistakes/typos – these can be just about anything – declaring a variable with one name and then having a type when using it again, typos of keywords, etc., etc.$4$@@$4$
$4$@@$4$
$4$@@$4$
II - Mixed usage of “s and ‘s when declaring a string and/or when using “/’s inside it (without escaping)$4$@@$4$
$4$@@$4$
$4$@@$4$
 III - Using ASSIGNMENT (=) operator instead of EQUALITY (==) operator -  declaring the variable (in if(…)) for example, instead of checking for an equality$4$@@$4$
$4$@@$4$
$4$@@$4$
 IV - Missing function parenthesis (brackets), after calling a function - for example, when declaring a variable to be the returned value of a certain function – such as *let varX = func* instead of *let varX = func( )*, the former will make varX = the function itself, whereas the latter will make varX = the returned value of the function$4$@@$4$
$4$@@$4$
$4$@@$4$
 V - Passing arguments in the wrong order – when arguments are passed, they have to the order of the parameters$4$@@$4$
$4$@@$4$
$4$@@$4$
 VI - Indexing errors – these can occur in multiple ways, the simplest is using the wrong index, at any index usage. A very basic error, would be when using a for loop, which iterates through an array and you want the loop to end when the array ends, but because of poor indexing, it ends earlier or later, and example of such a faulty test would be – i == array.length – where i would be your incrementor, these mistakes occur because you fail to see that index counting begins at 0(including), not 1, and on the other hand, the array length begins counting from 1 so in this case, the last index of your array would be array.length – 1, and so to stop the array at the right time, you would need: i > array.length, or at least i == array.length – 1$4$@@$4$
$4$@@$4$
$4$@@$4$
 VII - Creating infinite loops – this usually happens when loops lack a valid terminal condition, hence, the loop’s test is always true and so the loop iterates infinitely$4$@@$4$
$4$@@$4$
 VIII - Necessary/unnecessary reinitializing of variables within loops – if a variable is reinitialized in a loop and this variable needs to be incremented, for example for it to reach a false statement in the loop’s test – even though it is incremented, because of its re-initialization on every iteration, it doesn’t change and so – creates an infinite loop (for example) – ON THE OTHER HAND, lack of re-initialization may also lead to bugs, as for example, sometimes a value may need to be reinitialized for a certain function to work$4$@@$4$
$4$@@$4$
$4$@@$4$
 IX - Declaring variables within the wrong block, and/or creating “wrong” scopes for the given variable – since let variables have block scopes, it means that if you declare them within a code block, they will only be visible within that block, and trying to access them outside of the block in which they were declared will result to a bug – as you are trying to access an undefined variable (while it is defined, but only visible in the scope in which it is defined)
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Common_Bugs;
  