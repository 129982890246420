import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Getters_And_Setters() {
  const title = 'Getters And Setters'

  const paragraphs = {

    first: [insertNewLines(`To start off with a direct truth, getters and setters can have a further value, but in this part, the use of getters and setters will be purely “cosmetic”, that is to say, that is provides for different, arguable “more organized” syntax. $4$@@$4$
$4$@@$4$
    This has to be mentioned, because otherwise you might get confused, as to think that you need to use getters and setters or else different code for the same functionality may not work, or may get lost wondering why use getters and setters when you can use other “more basic” ways. And so that is the answer to this confusion – you can use other ways and for this sake, getters and setters will be only hold a syntax style purpose, with that said, let’s see what do getters and setters actually do.$4$@@$4$
    $4$@@$4$
    Firstly, let’s define our constructor class function and create an object:$4$@@$4$
    >>$4$@@$4$
    class CreateNewObjectFunction {$4$@@$4$
    $4$%%$4$        constructor(name, breed, colour) {$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogName = name;$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogBreed = breed;$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogColour = colour;$4$@@$4$
    $4$%%$4$            }
    }$4$@@$4$
    $4$@@$4$
    const dog1 = new CreateNewObject(“Arty”, “Labrador”, “Golden”);$4$@@$4$
    <<$4$@@$4$
    With this, we have created a new object – dog1 with the properties dogName, dogBreed and dogColour, with the respective values of Arty, Labrador and Golden.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    Let’s say that we want NEW PROPERTY, that has the value of a computation of the values (of other properties), such as adding a string, or having calculations being done with the values, we’d have to create a function in the object, for example:$4$@@$4$
    >>$4$@@$4$
    class CreateNewObjectFunction {$4$@@$4$
    $4$%%$4$        constructor(name, breed, colour) {$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogName = name;$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogBreed = breed;$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogColour = colour;$4$@@$4$
    $4$%%$4$            }$4$@@$4$
    $4$%%$4$fullLooks() { $4$@@$4$
    $4$%%$4$$4$%%$4$return ${'`${'}this.dogColour} ${'${'}this.dog.Breed}${'`'};$4$@@$4$
    $4$%%$4$                }$4$@@$4$
    }$4$@@$4$
    const dog1 = new CreateNewObject(“Arty”, “Labrador”, “Golden”);$4$@@$4$
    <<$4$@@$4$
    This way, we can now access the full looks of our dog1 by accessing:$4$@@$4$
    dog1.fullLooks();$4$@@$4$
    $4$@@$4$
    Well in ES6, getters we introduced to create the exact same code, but with a slightly cleaner syntax:$4$@@$4$
    >>$4$@@$4$
    class CreateNewObjectFunction {$4$@@$4$
    $4$%%$4$        constructor(name, breed, colour) {$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogName = name;$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogBreed = breed;$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogColour = colour;$4$@@$4$
    $4$%%$4$            }$4$@@$4$
    $4$%%$4$ get fullLooks() { $4$@@$4$
    $4$%%$4$$4$@@$4$return ${'`${'}this.dogColour} ${'${'}this.dog.Breed}${'`'};$4$%%$4$
     $4$@@$4$                      }$4$%%$4$
    }$4$%%$4$
$4$%%$4$
    const dog1 = new CreateNewObject(“Arty”, “Labrador”, “Golden”);$4$%%$4$
    <<$4$%%$4$
    adding the *get* keyword, allows the fullLooks function to behave and be accessed more as a property, than as a function, for example, now, instead of using dog1.fullLooks() to have the dogColour and dogBreed values return, you need to use just dog1.fullLooks – just as your regular properties.
    `)],

    second: [insertNewLines(`Okay, now, let’s say that you want to change A SINGLE PROPERTY – this is what the setters would accomplish, but first, just like before, let’s look at a regularly written function, without a setter, which would accomplish just the same task.$4$@@$4$
    >>$4$@@$4$
    class CreateNewObjectFunction {$4$@@$4$
    $4$%%$4$        constructor(name, breed, colour) {$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogName = name;$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogBreed = breed;$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogColour = colour;$4$@@$4$
    $4$%%$4$            }$4$@@$4$
    $4$%%$4$ get fullLooks() { $4$@@$4$
    $4$%%$4$$4$%%$4$             return ${'`${'}this.dogColour} ${'${'}this.dog.Breed}${'`'};$4$@@$4$
    $4$%%$4$                       }$4$@@$4$
    $4$%%$4$dogNameChange(newName) {$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogName = newName;$4$@@$4$
    $4$%%$4$                                            }$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    const dog1 = new CreateNewObject(“Arty”, “Labrador”, “Golden”);$4$@@$4$
    dog1.dogNameChange(“Bongi”);$4$@@$4$
    <<$4$@@$4$
    Here, the owner of dog1 in our database, has decided to change the name of his dog, and so we update it, with the function which we have created inside the object, instead of Arty now, dog1.dogName = Bongi.$4$@@$4$
    $4$@@$4$
    And here is a setter performing the same functionality:$4$@@$4$
    >> $4$@@$4$
    class CreateNewObjectFunction {$4$@@$4$
    $4$%%$4$        constructor(name, breed, colour) {$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogName = name;$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogBreed = breed;$4$@@$4$
    $4$%%$4$$4$%%$4$           this.dogColour = colour;$4$@@$4$
    $4$%%$4$            }$4$@@$4$
    $4$%%$4$ get fullLooks() { $4$@@$4$
    $4$%%$4$$4$%%$4$             return ${'`${'}this.dogColour} ${'${'}this.dog.Breed}${'`'};$4$@@$4$
    $4$%%$4$                       }$4$@@$4$
    $4$%%$4$$4$%%$4$ set dogNameChange(newName) {$4$@@$4$
    $4$%%$4$$4$%%$4$             this.dogName = newName;$4$@@$4$
    $4$%%$4$                          }$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    const dog1 = new CreateNewObject(“Arty”, “Labrador”, “Golden”);$4$@@$4$
    dog1.dogNameChange = “Bongi”$4$@@$4$
    <<      $4$@@$4$
    So, all in all, there is nothing different, other than that we have a set keyword before our dogNameChange function, and now, just as with the getter, instead of using the function call syntax, we can use the dogNameChange as a property, and it’s value = the input.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    Although we said that this is purely cosmetic, technically, it is, especially for setters, however, getters actually perform a certain useful action, which we don’t even realize. That is that when a getter is used (or a likewise function of it), the user – the one calling the getter/the function, is accessing the getter itself, and not the actual value that the getter/function returns.$4$@@$4$
    $4$@@$4$
    This can be useful, because let’s say that the user knows the name of the getter/function, but not of the actual value that they return, it technically makes our initial value a private value, since the user does not know about it. These values are usually marked with a _ before them, so for the case of our constructor function, we could place a _ before the name of every property – such as this._dogName. And lastly, if you technically make the getter/the function to compute the returned value in a specific way, it would mean that even the value of the original function will be unknown because it has been altered in some way.
    $4$@@$4$
    That said, though getters provide the ability to not directly access the property, setters DO directly change the property – do not confuse that they don’t or that they change another property while the original remains the same.$4$@@$4$
    `)],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Getters_And_Setters;
  