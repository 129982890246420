import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Base_Concepts_Of_Functional_Programming() {
  const title = 'Base Concepts Of Functional Programming'

  const paragraphs = {

    first: [insertNewLines(`Functional Programming, is a programming style, where the solutions are provided by simple, isolated functions, without any side effects outside of the functions cope:$4$@@$4$
    INPUT → PROCESS → OUTPUT$4$@@$4$
    $4$@@$4$
    Functional Programing is about:$4$@@$4$
    $4$@@$4$
    I - Isolated functions – there is no dependence on the state of the program, which includes global variables that are subject to change.$4$@@$4$
    $4$@@$4$
    II - Pure functions – the same input always gives the same output.$4$@@$4$
    $4$@@$4$
    III - Functions with limited side effects – any changes or mutations to the state of the program outside of the function are carefully controlled.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    Functional Programming terminology:$4$@@$4$
    $4$@@$4$
    Callback Functions – functions that are passed into other functions (hence, the function in which they are passed will decide how the callback is invoked)$4$@@$4$
    $4$@@$4$
    First Class Functions – functions that can be assigned to variables, passed into another function or returned from another function – like any other normal value – in JS, all functions are first class functions $4$@@$4$
    $4$@@$4$
    Higher Order Functions – functions that take a function as an argument OR, return a function as a return value`)],

    second: [insertNewLines(`All in all, the idea of Functional Programming is to not change the state of the program.$4$@@$4$
$4$@@$4$
    Here, a very basic example shows the idea of using functional programming, to create isolated, pure functions, that would give us what we want, WITHOUT changing the state of the program:$4$@@$4$
    >>$4$@@$4$
    let ourVariable = 3;$4$@@$4$
    $4$@@$4$
    function incrementingFunction( ) {$4$@@$4$
    $4$%%$4$return ourVariable + 1;$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    the incrementingFunction function will return the ourVariable variable +1, thus 4. This function will not alter the value of ourVariable, but still return an incremented value of ourVariable.$4$@@$4$
    $4$@@$4$
    However, this is still not an ISOLATED FUNCTION, as it still depends on the state of the program, that being, ourVariable and its value.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    In order to make the incrementingFunction function ISOLATED, we need to give it a parameter:$4$@@$4$
    >>$4$@@$4$
    let ourVariable = 3;$4$@@$4$
    $4$@@$4$
    function incrementingFunction(value) {$4$@@$4$
    $4$%%$4$return value + 1;$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    Now, the function does not depend on the state of the program and it will always return an incremented value of its input, WITHOUT changing the value of its input, which will therefore leave the state of the program UNCHANGED.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    This is obviously a very basic example of the usefulness of functional programming. With more complex needs, more complex function would be written, but the general idea remains, that it is about calculating (not necessarily mathematical calculations too) our code within functions– and when being calculated within these functions, we have just the result of these calculations returned – instead of having the calculations directly done on the variables and upon reaching the calculation’s result, the result itself is the changed value of the initial variable used within the calculations.
    `)],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Base_Concepts_Of_Functional_Programming;
  