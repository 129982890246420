import './Footer.css'

function Footer() {
  
  return (
    <footer className='Footer'>
        <h3>
        Copyright © 2023 - TENEV WEB DEV
        </h3>
    </footer> 
  );
}

export default Footer;
