import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Rest_And_Spread_Operators() {
  const title = 'Rest And Spread Operators'

  const paragraphs = {

    first: [insertNewLines(`ES6 offers ways to both condense  an array’s elements and “unpack” them, with the rest and spread operators, both represented with *…*, but the scenario in which they are used is what defines them:$4$@@$4$
    $4$@@$4$
    1 - REST, is when an array’s elements are CONDENSED FROM A LIST INTO AN ARRAY:$4$@@$4$
    $4$%%$4$for example, in a function, where we are provided with a list of arguments, these arguments will be condensed into an array and you can use array logic to work with them:$4$@@$4$
    >>$4$@@$4$
    function functionWithRestParameter(…args) {$4$@@$4$
    $4$%%$4$return “this function has ” + args.length + “ arguments, the first one is ” + args[0];$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    let arr1 = [1, ‘lol’, [1,2], true];$4$@@$4$
    $4$@@$4$
    let variableX = functionWithRestParameter(arr1);$4$@@$4$
    let variableY = functionwithRestParameter(“string1”, 1, [2,3], {}, false);$4$@@$4$
    <<$4$@@$4$
    In this example, variableX = “this function has 1 arguments, the first one is 1, ‘lol’, 1,2, true” and variableY = “this function has 5 arguments, the first one is string1”.$4$@@$4$
    $4$@@$4$
    The array is shown, so that you don’t confuse the operator, where you would supply an array – because you don’t supply an array – you supply a LIST and that list is turned into an ARRAY – so if you supply an array, it will be turned into an element of an array.$4$@@$4$
    $4$@@$4$
    $4$@@$4$
    2 - SPREAD, is when you “unpack” an ARRAY INTO A LIST:$4$@@$4$
     $4$%%$4$     in an example, where we want to copy one array into another:$4$@@$4$
    >>$4$@@$4$
    let arr1 = [1,2,3,4,5];$4$@@$4$
    let arr2 = […arr1];$4$@@$4$
    <<$4$@@$4$
    in the code above, arr1 is created, with its elements, arr2 is then an array, with the list of the elements from within arr2, therefore arr2 = [1,2,3,4,5] – NOTE – HERE THE ARRAY IS UNPACKED, THEREFORE, THE SPREAD OPERATOR RETURNS A LIST, AND SO WE HAVE TO ADD THE []S TO ARR2 FOR IT TO BECOME AN ARRAY, OTHERWISE WE WILL GET COMMA-SEPARATED VALUES, WHICH WILL GIVE AN ERROR (as it is trying to store multiple comma-separated values into the same variable).$4$@@$4$
    $4$@@$4$
    The spread operator can also be used to add the list in surrounding elements:$4$@@$4$
    >>$4$@@$4$
    let arr3 = [0, …arr1, 6,7]$4$@@$4$
    <<$4$@@$4$
    here arr 3 = [0,1,2,3,4,5,6,7];$4$@@$4$
    `)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Rest_And_Spread_Operators;
  