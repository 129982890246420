import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";

import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function SUSSY() {
  const title = ''

  const paragraphs = {

    first: [insertNewLines(`
    I - We know that we can use the .hasOwnProperty( ) method to check if an object owns a certain key (where true or false is returned).$4$@@$4$
    $4$@@$4$
    We can however, also use the syntax of:$4$@@$4$
    >$4$@@$4$
    ‘propertyName’ in objectName$4$@@$4$
    <$4$@@$4$
    where likewise, true or false will be returned$4$@@$4$
    $4$@@$4$
    for example:$4$@@$4$
    >>$4$@@$4$
    const obj1 = {$4$@@$4$
    $4$%%$4$prop1 : ‘val1’,$4$@@$4$
    $4$%%$4$prop2 : ‘val2’$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    console.log(‘prop1’ in obj1);$4$@@$4$
    <<$4$@@$4$
    The console will return true$4$@@$4$
    $4$@@$4$
    NOTE: Just as in .hasOwnProperty( ), if the property is accessed directly, you need quotes, and no quotes if it is accessed indirectly.$4$@@$4$
    And just like .hasOwnProperty( ), pop in obj – can be used as a test condition:$4$@@$4$
    if (prop in obj == true) {…}$4$@@$4$
$4$@@$4$
$4$@@$4$
    II - A for … in loop, can be used to iterate through an object’s properties.$4$@@$4$
    $4$@@$4$
    The loop works in the following way:$4$@@$4$
    1st – a variable is created & and object is given – within the syntax of the loop$4$@@$4$
    2nd – the NAME (NOT THE VALUE!) of the first property is assigned to the variable$4$@@$4$
    3rd – the loop iterates and the name of the next key is assigned to the variable$4$@@$4$
    4th – the process repeats until every key’s name has been cycled through$4$@@$4$
    $4$@@$4$
    To illustrate with an example:$4$@@$4$
    >>$4$@@$4$
    let obj2 = {$4$@@$4$
    $4$%%$4$p1 : 1,$4$@@$4$
    $4$%%$4$p2 : 2,$4$@@$4$
    $4$%%$4$p3 : 3$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    for (let variableName in obj2) {$4$@@$4$
    $4$%%$4$console.log(variableName);$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    The console will display p1, p2, p3$4$@@$4$
    $4$@@$4$
    The code in the code block can be any and you can use the variable name value to access each key as you iterate, JUST REMEMBER, that when you do, since the key’s name is stored in a variable, you will be accessing the keys INDIRECTLY, hence will need [ ]s WITH NO ‘’s$4$@@$4$
    $4$@@$4$
    To show an example usage of a for in loop(using the above obj2):$4$@@$4$
    >>$4$@@$4$
    let myArr = [ ];$4$@@$4$
    for (let looperProp in obj2){$4$@@$4$
    $4$%%$4$myArr.push(obj2[looperProp]);$4$@@$4$
    }$4$@@$4$
    <<$4$@@$4$
    This will create an array with THE VALUES (as even though looperProp has a value of the key’s NAMES, when using it with obj2[looperProp] – you are essentially using obj2.propName… which will access the value of the key) of the each key in obj2, so,$4$@@$4$
    myArr == [1,2,3]$4$%%$4$
    $4$@@$4$
    All in all, remember that using this method, you can iterate through an object’s properties, just like you can through an array’s elements with your basic for loop$4$@@$4$
    $4$@@$4$
$4$@@$4$
   III -The Object.keys( ) method, can be used to store all of the properties of a given object pathway, into an array$4$@@$4$
    $4$@@$4$
 
    The function takes the given object pathway as an argument$4$@@$4$
    For example:$4$@@$4$
    >>$4$@@$4$
    const obj3 = {$4$@@$4$
    $4$%%$4$p1 : 1,$4$@@$4$
    $4$%%$4$p2 : 2,$4$@@$4$
    $4$%%$4$p3 : { 
    $4$%%$4$$4$%%$4$    nestedProp1 : ‘nPVal1’,$4$@@$4$
    $4$%%$4$$4$%%$4$         nestedProp2 : ‘nPVal2’$4$@@$4$
    $4$%%$4$       }$4$@@$4$
    }$4$@@$4$
    $4$@@$4$
    let variable1 = Object.keys(obj3);$4$@@$4$
    let variable2 = Object.keys(obj3.p3);$4$@@$4$
    console.log(variable1, variable2);$4$@@$4$
    <<$4$@@$4$
    Console will display [p1, p2, p3] and [nestedProp1, nestedProp2]$4$@@$4$
    as each of the properties in the given object pathways has been converted into an array.`)],

    second: [],

    third: [],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default SUSSY;
  