import { memo } from "react";

function CreateBlocks({numberOfBlocks, blocksClassName, destination, appear, handleMouseEnter, handleMouseLeave, handleClick }){
    let blocksArray = [];

    for(let i = 0; i < numberOfBlocks; i ++){
      blocksArray.push(<div key={blocksClassName + i} className={blocksClassName}></div>)
    }

    console.log(destination)
    return (
        <div style={{pointerEvents: `${appear < 0 ? 'none' : 'unset'}`}}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={(e)=>{handleClick(e, destination)}} className='banner'>
            {blocksArray}
        </div>
    )
}

export default memo(CreateBlocks)