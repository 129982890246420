import { useState,useRef } from "react";
import { NavLink } from "react-router-dom";
import insertNewLines from "../../../../../utilities/insertNewLines";
import DelayedText1 from "../../../../About/components/DelayedText1";
import scrollToTop from "../../../../../utilities/scrollToTop";

function Arrays() {
  const title = 'Variables, Data Types and Values'

  const paragraphs = {

    first: [insertNewLines(`Arrays are lists of values, they can hold values of different data types simultaneously, here is an example array:$4$@@$4$

    >>$4$@@$4$
    let array1 = [“one”, 2, “true”, undefined];$4$@@$4$
    <<$4$@@$4$
    as you can see, array1 holds 4 values, each separated by a comma. The whole array begins with a --[-- and ends with a --]--, in a sense, the items are packaged within the brackets.$4$@@$4$
    $4$@@$4$
    However, counting within an array, starts from 0 and so the first time is 0, the 2nd is 1, 3rd is 2 and so on…$4$@@$4$
    to access each value, bracket notation is used:$4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    array1[0];$4$@@$4$
    <<$4$@@$4$
    the variable above will refer to the value “one”$4$@@$4$
    $4$@@$4$
    >> $4$@@$4$
    let x = array[2];$4$@@$4$
    <<$4$@@$4$
    the variable x will equal the Boolean value -true-.$4$@@$4$
    $4$@@$4$
    You can also have arrays within arrays:$4$@@$4$
    $4$@@$4$
    >>$4$@@$4$
    let array2 = [ [1,2,3], [“one”, “two”, “three”] ];$4$@@$4$
    <<$4$@@$4$
    
    Array2 holds 2 elements, both of which are arrays, with 3 elements$4$@@$4$
    $4$@@$4$
    array2 is hence a multidimensional array, as it has multiple levels, where the outermost level, is the first level - level 1$4$@@$4$
    in the case above, the array [1,2,3] exists in the first level, whereas the values 1,2,3 - exist in the second level$4$@@$4$
    $4$@@$4$
    in order to access multidimensional arrays, you use the following bracket logic:$4$@@$4$
    $4$@@$4$
    arrayName[index of element in first level][index of element in second level]$4$@@$4$
    $4$@@$4$
    for example, to get the string “one” from the above array2:$4$@@$4$
    >>$4$@@$4$
    let stringOne = array2[1][0];$4$@@$4$
    <<$4$@@$4$
    this way, stringOne will equal “one”, as the first bracket accessed the 2nd element - [1], at the first level - which is the array [“one”, “two”, “three”], then, the first item from this array is accessed by the [0] notation, reaching the string “one”$4$@@$4$
    $4$@@$4$
    there isn’t a particular limit to the number of levels within an array, for example:$4$@@$4$
    >>$4$@@$4$
    let superDeepArray = [ “level1”,[ “level2”,[ “level3”, [“last level”] ] ] ]$4$@@$4$
    <<$4$@@$4$
    to access the string “last level”, use bracket notation of [1][1][1][0] $4$@@$4$
    $4$@@$4$
    Arrays are MUTABLE - meaning that their values can be changed, as in the array:$4$@@$4$
    >>$4$@@$4$
    let array3 [1,2,3];$4$@@$4$
    array3[1] = “two”;$4$@@$4$
    <<$4$@@$4$
    now, the 2nd element within array3 will hold the string “two”`)],

    second: [insertNewLines(`The length of an array can be found by using .length after the array variable, for example:$4$@@$4$
    >>$4$@@$4$
    let array4 = [1,”two”,false, undefined, 5];$4$@@$4$
    let arrayLength = array4.length;$4$@@$4$
    <<$4$@@$4$
    here, the arrayLength variable will hold a value of 5, do note, that though indexing begins from 0, the .length of an array will return the actual number of the elements from an array, beginning from 1
    `)],

    third: [insertNewLines(`Arrays can also be manipulated:$4$@@$4$
    $4$@@$4$
    arrayName.push(element) - ADDS an element (the element within the brackets) to the very END of an array (after the previously last element)$4$@@$4$
    $4$@@$4$
    arrayName.pop() - REMOVES the element from the END of an array (the last element) - THE REMOVED VARIABLE IS ALSO RETURNED$4$@@$4$
    (so when popping an element, and assigning the code in the same time to a new variable, such as:$4$@@$4$
    let x = arr.pop(); - the last element will both be removed from the given array and x will then equal that removed element)$4$@@$4$
    $4$@@$4$
    arrayName.unshift(element) - ADDS an element to the very FRONT of an array (hence before the previously first element)$4$@@$4$
    $4$@@$4$
    arrayName.shift() - REMOVES the element from the very FRONT of an array (the first element) - THE REMOVED VARIABLE IS ALSO RETURNED $4$@@$4$
    $4$@@$4$ 
    to provide an example:$4$@@$4$
    >>$4$@@$4$
    let array5 = [1,2,3];$4$@@$4$
    array5.pop();$4$@@$4$
    array5.push(“three”);$4$@@$4$
    <<$4$@@$4$
    now array5 will equal [1,2,”three”]
    `)],

    forth: [],

    fifth: [],

    sixth: [],

    seventh: [],
  }

  const [colorMode,setColorMode] = useState(JSON.parse(localStorage.getItem("lightMode")))

  function handleColorModeClick(){
    localStorage.setItem("lightMode", JSON.stringify(!colorMode))
      setColorMode(JSON.parse(localStorage.getItem("lightMode")))
  }
  
  return (
    <div  className={colorMode ? 'BlogPage lightMode' : 'BlogPage'}>
      <button className='blog-page-color-mode-btn' onClick={handleColorModeClick} >
      {colorMode && <DelayedText1 type={69} content="DAY MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      {!colorMode && <DelayedText1 type={69} content="NIGHT MODE" typeSpeed={50} characterIterations={3} switch={false}/>}
      </button>
      <div className="key-container">
          <div>
              <h3>Key:</h3>
              <h4><span>{">> ... <<"}</span> <span>: literal code example</span></h4>
              <h4><span>{"> ... <"}</span> <span>: pseudo code example</span></h4>
              <h4><span>{"- ... -"}</span> <span>: quoting a string/value</span></h4>
              <h4><span>{"-- ... --"}</span> <span>: quoting a symbol</span></h4>
          </div>
        </div>
        <h1>{title}</h1>
        <hr className="splitter-hr"/>
        <div className='blog-page-container' >
          {paragraphs.first.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.first[0] && <hr className="splitter-hr"/>}

          {paragraphs.second.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.second[0] && <hr className="splitter-hr"/>}

          {paragraphs.third.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.third[0] && <hr className="splitter-hr"/>}

          {paragraphs.forth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.forth[0] && <hr className="splitter-hr"/>}

          {paragraphs.fifth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.fifth[0] && <hr className="splitter-hr"/>}

          {paragraphs.sixth.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.sixth[0] && <hr className="splitter-hr"/>}

          {paragraphs.seventh.map((element, index) => <p key={element[0] + (element.length / 3).toFixed(0) + index}>{element}</p>)}
          {paragraphs.seventh[0] && <hr className="splitter-hr"/>}
        </div>
        <div className='back-nav-container'>
        <NavLink to='/blog' onClick={scrollToTop}><button className="blog-return-btn" >BACK TO BLOG</button></NavLink>
        </div>
    </div>
  );
  }
  
  export default Arrays;
  