import { useMemo, useRef, useEffect } from "react"
import { Mesh } from "three"
import { useFrame, useLoader } from "react-three-fiber"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { useLoadedStateChangeContext } from "../../context/3dModelLoadContext/3dModelLoadContext";


export function Home3dLargeScreenModel( { stop } ) {

  const loaded = useLoadedStateChangeContext()

  useEffect(()=>{
    loaded()
  },[])

    let meshRef = useRef(null)
    const gltf = useLoader(GLTFLoader, '../scene.gltf')
  
    useEffect(() => {
  
          gltf.scene.traverse((object)=>{
            if(object instanceof Mesh){
              // object.castShadow = true;
              // object.receiveShadow = true;
              // object.material.envMapIntensity = 10
              object.material.opacity = 0.9; // set the opacity to 50%
          object.material.transparent = true; // enable transparency
          object.material.roughness = 0.01; // set the roughness to 0.1
          object.material.metalness = 2.2; // set the metalness to 0.9
          // object.material.normalScale.set(5, 5); // set normal scale to 1
          // object.material.emissive.set('purple');
          // object.material.color = new Color('#4B3CB2 ');
  
            }
          })
        },[gltf])
  
        
    useFrame((state, delta) => {
      if(!stop){
        meshRef.current.rotation.x += 0.0018;
      meshRef.current.rotation.y += 0.002;
      }
      
    });
  
    const memoizedModel = useMemo(() => gltf.scene, [gltf.scene]);
    
    return (
      <primitive ref={meshRef} object={memoizedModel} scale={0.25} position={[0,0,0]} />
    )
  }
  